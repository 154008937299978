import { ColumnOfTable } from 'app/components/table/sortableTable'
import {
  capitalize,
  capitalizeAllCharacters,
  parseToNumber,
} from 'utils/string'
import { ISortType } from './types'

export const DOCUMENT_INVALIDATIONS_TABLE_KEY = 'documentInvalidationsTable'

export const columnsForShowInDocumentInvalidationsTable: ColumnOfTable[] = [
  { id: 'id', label: 'ID' },
  { id: 'adminID', label: 'AdminID' },
  { id: 'bctxID', label: 'BctxID' },
  { id: 'reason', label: 'Reason' },
  { id: 'status', label: 'Status' },
  { id: 'submissionID', label: 'SubmissionID' },
  { id: 'txHash', label: 'TxHash' },
  { id: 'userCoreID', label: 'UserCoreID' },
  { id: 'vouchererName', label: 'VouchererName' },
  { id: 'createdAt', label: 'CreatedAt', isDate: true },
  { id: 'updatedAt', label: 'UpdatedAt', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${DOCUMENT_INVALIDATIONS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${DOCUMENT_INVALIDATIONS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${DOCUMENT_INVALIDATIONS_TABLE_KEY}-orderBy`) ??
  undefined

export const MAX_SAFE_INTEGER = 10000

export const DOCUMENT_INVALIDATIONS_STATUS_OPTIONS = [
  'SUCCESSFUL',
  'NEWLY_ADDED',
  'UNKNOWN_FAILED',
  'IN_PROGRESS',
  'FAILED',
]
