//FIXME: we must remove this folder and this is a templete

import { DocumentInvalidationsHeader } from './components/Header'
import { FilterDocumentInvalidationsModal } from './components/modals/filter'
import { DocumentInvalidationsTable } from './components/table'
import { Wrapper } from './styles'

interface Props {}

export function DocumentInvalidations(props: Props) {
  return (
    <Wrapper>
      <DocumentInvalidationsHeader />
      <DocumentInvalidationsTable />
      <FilterDocumentInvalidationsModal />
    </Wrapper>
  )
}
