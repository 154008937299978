import { useDispatch, useSelector } from 'react-redux'
import { TabTitle, TitleWrapper, Wrapper } from './styles'
import { rolesActions } from './slice'
import { rolesSelectors } from './selectors'
import { useEffect } from 'react'
import { PageLoading } from 'app/components/common/loading/pageLoading'
import RoleCard from 'app/containers/Roles/components/card/role-card'
import { Role } from './types'
import { DeleteConfirmationModal } from './components/modals/confirmation'
import { AddRoleModal } from './components/modals/add-role'
import { ExportButton } from 'app/components/button/exportButton'

interface Props {}

export function Roles(props: Props) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(rolesActions.fetchRoles({}))
    dispatch(rolesActions.fetchRoutes())
  }, [])

  const rolesList = useSelector(rolesSelectors.rolesList)
  const isLoadingRoles = useSelector(rolesSelectors.isLoadingRoles)

  const handleAddClick = () => {
    dispatch(rolesActions.setAddRoleModalDisplay(true))
  }

  return (
    <>
      <AddRoleModal />
      <DeleteConfirmationModal />
      <Wrapper>
        {isLoadingRoles ? (
          <PageLoading />
        ) : (
          <>
            <TitleWrapper>
              <TabTitle>Roles</TabTitle>
              <ExportButton onClick={handleAddClick}>Add New Role</ExportButton>
            </TitleWrapper>
            {rolesList.map((role: Role) => {
              return <RoleCard key={role.id} roleId={role.id} />
              // return <div key={role.ID}>{role.Name}</div>
            })}
          </>
        )}
      </Wrapper>
    </>
  )
}
