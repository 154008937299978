import { ColumnOfTable } from 'app/components/table/sortableTable'
import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'

export const OAUTHS_TABLE_KEY = 'oauthsTable'

export const columnsForShowInOauthsTable: ColumnOfTable[] = [
  { id: 'name', label: 'Name' },
  { id: 'clientDomainID', label: 'Client DomainID' },
  { id: 'clientDomainUuid', label: 'Client DomainUUID' },
  { id: 'uuID', label: 'UUID' },
  { id: 'oauth2ClientID', label: 'Oauth2 ClientID' },
  { id: 'secret', label: 'Secret' },
  { id: 'scope', label: 'Scope' },
  { id: 'tokenEndpointAuthMethod', label: 'Token Endpoint Auth Method' },
  { id: 'grantTypes', label: 'Grant Types' },
  { id: 'responseTypes', label: 'Response Types' },
  { id: 'redirectUris', label: 'Redirect Uris' },
  { id: 'audiencees', label: 'Audiencees' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updateAt', label: 'Update At', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${OAUTHS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${OAUTHS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${OAUTHS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000
