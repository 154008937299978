import { ColumnOfTable } from 'app/components/table/sortableTable'
import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'

export const CLIENTS_TABLE_KEY = 'clientsTable'

export const columnsForShowInClientsTable: ColumnOfTable[] = [
  { id: 'corepassID', label: 'CorepassID' },
  { id: 'country', label: 'Country' },
  { id: 'email', label: 'Email' },
  { id: 'name', label: 'Name' },
  { id: 'phone', label: 'Phone' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updatedAt', label: 'Updated At', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${CLIENTS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${CLIENTS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${CLIENTS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000
