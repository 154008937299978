/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction } from '@reduxjs/toolkit'
import { LoginStatus, ContainerState, JWTencodedData } from './types'
import { createSlice } from 'store/toolkit'
import { useInjectReducer, useInjectSaga } from 'store/redux-injectors'

import { loginSaga } from './saga'
import { LocalStorageKeys } from 'store/storage'
import jwt_decode from 'jwt-decode'

// The initial state of the Login container
export const initialState: ContainerState = {
  qrcode: null,
  linkToPhone: null,
  coreId: (() => {
    const authToken = localStorage.getItem(LocalStorageKeys.AUTH_ACCESS_TOKEN)
    if (!authToken) {
      return ''
    } else {
      var decodedJWT: JWTencodedData = jwt_decode(authToken)
      const coreID = decodedJWT.sub.split(':')[1]
      return coreID
    }
  })(),
  authCode: '',
  loginStatus: LoginStatus.init,
  tokenLimitEnd: null,
}

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAuthCode(state, action: PayloadAction<string>) {
      state.authCode = action.payload
    },
    setQrCode(state, action: PayloadAction<string>) {
      state.qrcode = action.payload
    },
    setLinkToPhone(state, action: PayloadAction<string>) {
      state.linkToPhone = action.payload
    },

    claimWithAddress() {},
    login() {},
    setCoreId(state, action: PayloadAction<string>) {
      state.coreId = action.payload
    },

    setTokenLimitEnd(state, action: PayloadAction<number>) {
      state.tokenLimitEnd = action.payload
    },

    setLoginStatus(state, action: PayloadAction<LoginStatus>) {
      state.loginStatus = action.payload
    },
    logout(state) {
      localStorage.removeItem('AUTH_ACCESS_TOKEN')
      localStorage.removeItem('isLoggingIn')
      localStorage.removeItem('Organization-UUID')
      localStorage.removeItem(LocalStorageKeys.SIDEBAR_OPEN_CATEGORY)
      state.loginStatus = LoginStatus.init
    },
  },
})

export const {
  actions: loginActions,
  reducer: loginReducer,
  name: sliceKey,
} = loginSlice

export const useloginSlice = () => {
  useInjectReducer({ key: sliceKey, reducer: loginReducer })
  useInjectSaga({ key: sliceKey, saga: loginSaga })
  return { loginActions }
}
