//FIXME: we must remove this folder and this is a templete

import { useDispatch, useSelector } from 'react-redux'
import { AdminsContainer, TabTitle, TitleWrapper, Wrapper } from './styles'
import { adminsActions } from './slice'
import { adminsSelectors } from './selectors'
import { useEffect } from 'react'
import { PageLoading } from 'app/components/common/loading/pageLoading'
import { DeleteConfirmationModal } from './components/modals/delete-confirmation'
import { Admin } from './types'
import AdminCard from './components/card/admin-card'
import { rolesActions } from '../Roles/slice'
import { Button } from '@mui/material'
import { AddAdminModal } from './components/modals/add-admin'
import { ExportButton } from 'app/components/button/exportButton'

interface Props {}

export function Admins(props: Props) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(adminsActions.fetchAdmins({}))
    dispatch(rolesActions.fetchRoles({}))
  }, [])

  const adminsList = useSelector(adminsSelectors.adminList)
  const isLoadingAdmins = useSelector(adminsSelectors.isLoadingAdmins)

  const handleAddClick = () => {
    dispatch(adminsActions.setAddAdminModalDisplay(true))
  }

  return (
    <>
      <DeleteConfirmationModal />
      <AddAdminModal />
      <Wrapper>
        {isLoadingAdmins ? (
          <PageLoading />
        ) : (
          <>
            <TitleWrapper>
              <TabTitle>Admins</TabTitle>
              <ExportButton onClick={handleAddClick}>
                Add New Admin
              </ExportButton>
            </TitleWrapper>
            <AdminsContainer>
              {adminsList?.map((admin: Admin, index) => {
                return <AdminCard key={index} admin={admin} />
                // return <div key={role.ID}>{role.Name}</div>
              })}
            </AdminsContainer>
          </>
        )}
      </Wrapper>
    </>
  )
}
