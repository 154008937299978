import * as React from 'react'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import { visuallyHidden } from '@mui/utils'
import { styled } from '@mui/material'
import { shortDateFormat } from 'utils/date'
import { ThreeDotLoadingIndicator } from 'app/components/common/loading/ThreeDotLoadingIndicator/threeDotLoading'

interface Data {
  [key: string]: any
}

interface CustomComparators {
  (a: any, b: any): number
}

export interface ColumnOfTable {
  id: string
  label: string
  isDate?: boolean
  actionKey?: string
}

export interface SpeceficRowStyleBasedOnCellValueOfTable {
  cellKey: string
  cellValue: string | boolean | number
  style: React.CSSProperties
  actionComponent: React.FC<any>
}

interface SortableTableProps {
  name: string
  data: Data[]
  columns: ColumnOfTable[]
  onRowClick?: (row: Data) => void
  onCellClick?: (row: Data, column: string) => void
  page?: number
  setPage?: (page: number) => void
  handleChangeLastPage?: (newPage: number) => void
  customComparators?: CustomComparators
  columnCustomComparator?: string
  setNumberRowsPerPage?: (num: number) => void
  handleCustomRequestSort?: (property: string, order: Order) => void
  showNumberOfPages?: boolean
  countOfData?: number
  disableNextButton?: boolean
  isLoading?: boolean
  speceficRowStyleBasedOnCellValue?: SpeceficRowStyleBasedOnCellValueOfTable
}

type Order = 'asc' | 'desc'

function SortableTable({
  name,
  data,
  columns,
  onRowClick,
  onCellClick = () => {},
  handleChangeLastPage = () => {},
  page: pageProp = 0,
  setPage: setPageProp = () => {},
  customComparators,
  columnCustomComparator,
  setNumberRowsPerPage = (num: number) => {},
  handleCustomRequestSort = () => {},
  showNumberOfPages = true,
  countOfData = 0,
  disableNextButton = false,
  isLoading = false,
  speceficRowStyleBasedOnCellValue,
}: SortableTableProps) {
  const [order, setOrder] = React.useState<Order>(
    (localStorage.getItem(`${name}-order`) as Order) || 'asc',
  )
  const [orderBy, setOrderBy] = React.useState<string>(
    localStorage.getItem(`${name}-orderBy`) || '',
  )

  const [page, setPage] = React.useState(pageProp)

  const localPageSize = localStorage.getItem(`${name}_pageSize`)
  const initialValueOfRowsPerPage = localPageSize
    ? +localPageSize
    : undefined || 100

  const [rowsPerPage, setRowsPerPage] = React.useState(
    initialValueOfRowsPerPage,
  )

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    localStorage.setItem(`${name}-orderBy`, property)
    localStorage.setItem(`${name}-order`, isAsc ? 'desc' : 'asc')
    handleCustomRequestSort(property, isAsc ? 'desc' : 'asc')
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
    setPageProp(newPage)
    handleChangeLastPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setNumberRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setPageProp(0)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number,
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    if (customComparators && columnCustomComparator === orderBy) {
      return order === 'desc'
        ? (a, b) => customComparators(a, b)
        : (a, b) => -customComparators(a, b)
    }

    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const visibleRows = React.useMemo(
    () => stableSort(data, getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, data],
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          boxShadow: '5px 10px 10px 1px rgba(0, 0, 0, 0.1)',
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <StyledTableCell
                    sx={{ fontWeight: 700, fontSize: '16px' }}
                    key={column.id}
                    align="left"
                    padding="normal"
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={() => handleRequestSort(column.id)}
                    >
                      {column.label}
                      {orderBy === column.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <ThreeDotLoadingIndicator />{' '}
                  </TableCell>
                </TableRow>
              ) : visibleRows.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    align="center"
                    sx={{ fontSize: '18px', fontWeight: 700, padding: '20px' }}
                  >
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        if (onRowClick) onRowClick(row)
                      }}
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'pointer' }}
                      style={
                        speceficRowStyleBasedOnCellValue &&
                        row[speceficRowStyleBasedOnCellValue?.cellKey] ===
                          speceficRowStyleBasedOnCellValue?.cellValue
                          ? speceficRowStyleBasedOnCellValue?.style
                          : {}
                      }
                    >
                      {columns.map(column => {
                        return (
                          <StyledTableCell
                            key={column.id}
                            align="left"
                            padding="normal"
                            sx={{ fontSize: '14px' }}
                            onClick={() => onCellClick(row, column.id)}
                          >
                            {column.isDate && typeof row[column.id] === 'number'
                              ? shortDateFormat(+row[column.id])
                              : column.id === 'action'
                                ? React.createElement(
                                    speceficRowStyleBasedOnCellValue?.actionComponent!,
                                    {
                                      rowId: row[column.actionKey!] as string,
                                      actionHasBeenDone: !!(
                                        speceficRowStyleBasedOnCellValue?.cellKey &&
                                        row[
                                          speceficRowStyleBasedOnCellValue
                                            ?.cellKey
                                        ] ===
                                          speceficRowStyleBasedOnCellValue?.cellValue
                                      ),
                                    },
                                  )
                                : row[column.id]?.toString()}
                          </StyledTableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              )}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={columns.length} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[100, 200, 300]}
          component="div"
          count={countOfData ? -1 : data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            '& .MuiTablePagination-displayedRows': {
              display: showNumberOfPages ? 'block' : 'none',
            },
          }}
          nextIconButtonProps={{
            disabled: disableNextButton,
          }}
        />
      </Paper>
    </Box>
  )
}

export default SortableTable

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[300],
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
}))
