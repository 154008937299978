//FIXME: we must remove this folder and this is a templete

import { Wrapper } from './styles'
import { EmailVerificationsTable } from './components/table'
import { EmailVerificationsHeader } from './components/Header'
import { FilterEmailVerificationsModal } from './components/modals/filter'

interface Props {}

export function EmailVerifications(props: Props) {
  return (
    <Wrapper>
      <EmailVerificationsHeader />
      <EmailVerificationsTable />
      <FilterEmailVerificationsModal />
    </Wrapper>
  )
}
