//for all styles of test scope

import styled from 'styled-components'
import { mediaQueries } from 'styles/mediaQueries'

export const Wrapper = styled.div`
  padding: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  padding: 16px;
  `}
`
