import { ColumnOfTable } from 'app/components/table/sortableTable'
import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'

export const ORGANIZATIONS_TABLE_KEY = 'organizationsTable'

export const columnsForShowInOrganizationsTable: ColumnOfTable[] = [
  { id: 'name', label: 'Name' },
  { id: 'bypassPayment', label: 'Bypass Payment' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updateAt', label: 'Update At', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${ORGANIZATIONS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${ORGANIZATIONS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${ORGANIZATIONS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000
