import { organizationsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { organizationsSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  MAX_SAFE_INTEGER,
  ORGANIZATIONS_TABLE_KEY,
  columnsForShowInOrganizationsTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from '../types'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const organizationsList = useSelector(
    organizationsSelectors.organizationsList,
  )
  const isLoadingOrganizations = useSelector(
    organizationsSelectors.isLoadingOrganizations,
  )
  const filterOrganizations = useSelector(
    organizationsSelectors.filterOrganizations,
  )
  const lastPageOfOrganizationsTable = useSelector(
    organizationsSelectors.lastPageOfOrganizationsTable,
  )
  const isAllOrganizationsFetched = useSelector(
    organizationsSelectors.isAllOrganizationsFetched,
  )

  useEffect(() => {
    dispatch(organizationsActions.getOrganizationsList())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${ORGANIZATIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      organizationsActions.setFilterOrganizations({
        ...filterOrganizations,
        pageSize: num,
      }),
    )
    dispatch(organizationsActions.getOrganizationsList())
  }

  return (
    <Wrapper>
      <SortableTable
        name={ORGANIZATIONS_TABLE_KEY}
        isLoading={isLoadingOrganizations}
        data={organizationsList}
        columns={columnsForShowInOrganizationsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            organizationsActions.setFilterOrganizations({
              ...filterOrganizations,
              page: newPage,
            }),
          )

          dispatch(organizationsActions.getOrganizationsList())
        }}
        setPage={page =>
          dispatch(organizationsActions.setLastPageOfOrganizationsTable(page))
        }
        page={lastPageOfOrganizationsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            organizationsActions.setFilterOrganizations({
              ...filterOrganizations,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = organizationsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(organizationsActions.getOrganizationsList())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllOrganizationsFetched}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
