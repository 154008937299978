import { Wrapper } from './styles'
import { TableOfData } from './components/table'
import { Header } from './components/Header'
import { FilterVouchersModal } from './components/modals/filter'

interface Props {}

export function Vouchers(props: Props) {
  return (
    <Wrapper>
      <Header />
      <TableOfData />
      <FilterVouchersModal />
    </Wrapper>
  )
}
