//FIXME: we must remove this folder and this is a templete

import { Header } from './components/Header'
import { FilterClientsModal } from './components/modals/filter'
import { TableOfData } from './components/table'
import { Wrapper } from './styles'

interface Props {}

export function Clients(props: Props) {
  return (
    <Wrapper>
      <Header />
      <TableOfData />
      <FilterClientsModal />
    </Wrapper>
  )
}
