import { History } from 'history'
import { FC, ReactElement, useLayoutEffect, useState } from 'react'
import { Navigate, Outlet, Route, Router, Routes } from 'react-router-dom'
import history from '../router/history'
import { AppPages } from './constants'
import { NotFoundPage } from './containers/NotFound'
import { Login } from './containers/Login/Loadable'
import { PrivateRoute } from 'router/private_route'
import InitializeApp from './containers/Initialize'
import { routes as appRoutes } from './routes'
import { AppLayout } from './components/common/layouts/appLayout'

interface CustomRouterProps {
  history: History
  children?: ReactElement
}

const CustomRouter: FC<CustomRouterProps> = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}
export const NavLayout = () => (
  <>
    <AppLayout>
      <Outlet />
    </AppLayout>
  </>
)

function App() {
  return (
    <>
      <CustomRouter history={history}>
        <>
          <InitializeApp />
          <Routes>
            {/* Including SideBar */}
            <Route path={AppPages.Sidebar} element={<NavLayout />}>
              {/* FIXME: change the root url base on the ux of this project */}
              {/* Redirect the root URL to "/test" */}
              <Route
                path="/"
                element={<Navigate to="/resubmissions" replace />}
              />
              {appRoutes.map(route => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={
                    <PrivateRoute>
                      <route.component />
                    </PrivateRoute>
                  }
                />
              ))}
            </Route>
            {/* No SideBar */}
            <Route path={AppPages.Login} element={<Login />} />
            <Route path={AppPages.LoginCallBack} element={<Login />} />
            <Route path={AppPages.NotFoundPage} element={<NotFoundPage />} />
          </Routes>
        </>
      </CustomRouter>
    </>
  )
}

export default App
