import { ColumnOfTable } from 'app/components/table/sortableTable'
import {
  capitalize,
  capitalizeAllCharacters,
  parseToNumber,
} from 'utils/string'
import { ISortType } from './types'

export const TRANSMISSIONS_TABLE_KEY = 'transmissionsTable'

export const columnsForShowInTransmissionsTable: ColumnOfTable[] = [
  { id: 'userCoreID', label: 'User CoreID' },
  { id: 'confirmTxHash', label: 'Confirm TxHash' },
  { id: 'failureReason', label: 'Failure Reason' },
  { id: 'initiateTxHash', label: 'Initiate TxHash' },
  { id: 'orderStatus', label: 'Order Status' },
  { id: 'plan', label: 'Plan' },
  { id: 'price', label: 'Price' },
  { id: 'referenceKey', label: 'Reference Key' },
  { id: 'sourceKey', label: 'Source Key' },
  { id: 'transmissionID', label: 'Transmission ID' },
  { id: 'clientDomainUUID', label: 'Client DomainUUID' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'expiration', label: 'Expiration', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${TRANSMISSIONS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${TRANSMISSIONS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${TRANSMISSIONS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000

export const STATUS_OPTIONS = [
  'PENDING',
  'INITIATING',
  'INITIATED',
  'CONFIRMING',
  'CONFIRMED',
  'FAILED',
]
export const PLAN_OPTIONS = ['CHARGE', 'BUNDLE']
