import { ColumnOfTable } from 'app/components/table/sortableTable'
import {
  capitalize,
  capitalizeAllCharacters,
  parseToNumber,
} from 'utils/string'
import { ISortType } from './types'

export const DOCUMENT_REJECTIONS_TABLE_KEY = 'documentRejectionsTable'

export const columnsForShowInDocumentRejectionsTable: ColumnOfTable[] = [
  { id: 'adminID', label: 'Admin ID' },
  { id: 'bctxID', label: 'Bctx ID' },
  { id: 'id', label: 'ID' },
  { id: 'nftID', label: 'NFT ID' },
  { id: 'userCoreID', label: 'User Core ID' },
  { id: 'vouchererName', label: 'Voucherer Name' },
  { id: 'status', label: 'Status' },
  { id: 'reason', label: 'Reason' },
  { id: 'txHash', label: 'Tx Hash' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updatedAt', label: 'Updated At', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${DOCUMENT_REJECTIONS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${DOCUMENT_REJECTIONS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${DOCUMENT_REJECTIONS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000

export const DOCUMENT_REJECTIONS_STATUS_OPTIONS = [
  'SUCCESSFUL',
  'UNKNOWN_FAILED',
  'IN_PROGRESS',
  'FAILED',
]
