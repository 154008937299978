import { css } from 'styled-components'

const CommonTypographyStyle = css`
  text-align: center;
  letter-spacing: -0.01em;
  font-style: normal;
`

//HeadLine H1
export const H1 = css`
  ${CommonTypographyStyle}
  font-size: 72px;
  line-height: 90px;
`

export const H1_REGULAR = css`
  ${H1}
  font-weight: 400;
`

export const H1_MEDIUM = css`
  ${H1}
  font-weight: 500;
`

export const H1_SEMIBOLD = css`
  ${H1}
  font-weight: 600;
`

export const H1_BOLD = css`
  ${H1}
  font-weight: 700;
`

//HeadLine H2

export const H2 = css`
  ${CommonTypographyStyle}
  font-size: 30px;
  line-height: 38px;
`

export const H2_REGULAR = css`
  ${H2}
  font-weight: 400;
`

export const H2_MEDIUM = css`
  ${H2}
  font-weight: 500;
`

export const H2_SEMIBOLD = css`
  ${H2}
  font-weight: 600;
`

export const H2_BOLD = css`
  ${H2}
  font-weight: 700;
`

//HeadLine H2

export const H3 = css`
  ${CommonTypographyStyle}
  font-size: 24px;
  line-height: 32px;
`

export const H3_REGULAR = css`
  ${H3}
  font-weight: 400;
`

export const H3_MEDIUM = css`
  ${H3}
  font-weight: 500;
`

export const H3_SEMIBOLD = css`
  ${H3}
  font-weight: 600;
`

export const H3_BOLD = css`
  ${H3}
  font-weight: 700;
`

//Text Extra Small

export const TEXT_XS = css`
  ${CommonTypographyStyle}
  font-size: 10px;
  line-height: 14px;
`

export const TEXT_XS_REGULAR = css`
  ${TEXT_XS}
  font-weight: 400;
`

export const TEXT_XS_MEDIUM = css`
  ${TEXT_XS}
  font-weight: 500;
`

export const TEXT_XS_SEMIBOLD = css`
  ${TEXT_XS}
  font-weight: 600;
`

//Text Small

export const TEXT_SM = css`
  ${CommonTypographyStyle}
  font-size: 12px;
  line-height: 18px;
`

export const TEXT_SM_REGULAR = css`
  ${TEXT_SM}
  font-weight: 400;
`

export const TEXT_SM_MEDIUM = css`
  ${TEXT_SM}
  font-weight: 500;
`

export const TEXT_SM_BOLD = css`
  ${TEXT_SM}
  font-weight: 700;
`

//Text Medium

export const TEXT_M = css`
  ${CommonTypographyStyle}
  font-size: 14px;
  line-height: 24px;
`

export const TEXT_M_REGULAR = css`
  ${TEXT_M}
  font-weight: 400;
`

export const TEXT_M_MEDIUM = css`
  ${TEXT_M}
  font-weight: 500;
`

export const TEXT_M_SEMIBOLD = css`
  ${TEXT_M}
  font-weight: 600;
`

export const TEXT_M_BOLD = css`
  ${TEXT_M}
  font-weight: 700;
`

//Text Large

export const TEXT_LG = css`
  ${CommonTypographyStyle}
  font-size: 16px;
  line-height: 24px;
`

export const TEXT_LG_REGULAR = css`
  ${TEXT_LG}
  font-weight: 400;
`

export const TEXT_LG_MEDIUM = css`
  ${TEXT_LG}
  font-weight: 500;
`

export const TEXT_LG_SEMIBOLD = css`
  ${TEXT_LG}
  font-weight: 600;
`

//Text Extra Large
export const TEXT_XL = css`
  ${CommonTypographyStyle}
  font-size: 18px;
  line-height: 28px;
`

export const TEXT_XL_REGULAR = css`
  ${TEXT_XL}
  font-weight: 400;
`

export const TEXT_XL_MEDIUM = css`
  ${TEXT_XL}
  font-weight: 500;
`

// global typography

const CommonStyle = css`
  text-align: center;
  letter-spacing: -0.02em;
  font-style: normal;
`

export const H4 = css`
  ${CommonStyle}
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
`
export const H5 = css`
  ${CommonStyle}
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`
export const H5_light = css`
  ${CommonStyle}
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`
//B1 20/30
export const B1_Large_Regular = css`
  ${CommonStyle}
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`
export const B1_Large_Medium = css`
  ${CommonStyle}
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
`
export const B1_Large_Semibold = css`
  ${CommonStyle}
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
`
export const B1_Medium_Regular = css`
  ${CommonStyle}
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
export const B1_Medium_Medium = css`
  ${CommonStyle}
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`
export const B1_Medium_Semibold = css`
  ${CommonStyle}
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
export const B2_Large_Regular = css`
  ${CommonStyle}
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
`
export const B2_Large_Medium = css`
  ${CommonStyle}
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
`
export const B2_Large_Semibold = css`
  ${CommonStyle}
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
`
export const B2_Medium_Regular = css`
  ${CommonStyle}
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`
export const B2_Medium_Medium = css`
  ${CommonStyle}
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
export const B2_Medium_Semibold = css`
  ${CommonStyle}
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
export const C1_Regular = css`
  ${CommonStyle}
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`
export const C1_Medium = css`
  ${CommonStyle}
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`
export const C1_Semibold = css`
  ${CommonStyle}
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
`
export const C2_Regular = css`
  ${CommonStyle}
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`
export const C2_Medium = css`
  ${CommonStyle}
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`
export const C2_Semibold = css`
  ${CommonStyle}
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`
export const C3_Regular = css`
  ${CommonStyle}
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
`
export const C3_Medium = css`
  ${CommonStyle}
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
`
export const C3_Semibold = css`
  ${CommonStyle}
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
`
export const C4_Medium = css`
  ${CommonStyle}
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
`
