import { Chip } from '@mui/material'
import { rolesSelectors } from 'app/containers/Roles/selectors'
import { Role } from 'app/containers/Roles/types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ROW_ALIGN_START__JUSTIFY_START } from 'styles/globalStyles'
import { Admin } from '../../types'
import { adminsActions } from '../../slice'
import { adminsSelectors } from '../../selectors'
import { mediaQueries } from 'styles/mediaQueries'

interface RolesListProps {
  admin: Admin
}
export const RolesList = ({ admin }: RolesListProps) => {
  const dispatch = useDispatch()
  const rolesList = useSelector(rolesSelectors.rolesList)

  const isAddingRole = useSelector(adminsSelectors.isAddingRole)
  const roleAddingIDOfAdmin = useSelector(adminsSelectors.roleAddingIDOfAdmin)

  const adminRoles = admin.roleInfo
  const adminID = admin.id
  const handleDelete = (role: Role) => {
    dispatch(
      adminsActions.setDeleteConfirmationModalData({
        display: true,
        admin,
        role,
      }),
    )
  }
  const handleClick = (role: Role) => {
    dispatch(adminsActions.addRoleToAdmin({ admin, role }))
    dispatch(adminsActions.setRoleAddingIDOfAdmin(adminID))
  }
  return (
    <RoutesWrapper>
      {rolesList?.map(rolesListItem => {
        const isRouteSelected = adminRoles.some(
          adminRole => adminRole.id === rolesListItem.id,
        )

        return isRouteSelected ? (
          <ChipStyle
            key={rolesListItem.id}
            label={rolesListItem.name}
            onClick={() => handleDelete(rolesListItem)}
            onDelete={() => handleDelete(rolesListItem)}
            variant="outlined"
            color="success"
          />
        ) : (
          <ChipStyle
            key={rolesListItem.id}
            label={rolesListItem.name}
            onClick={() => handleClick(rolesListItem)}
            disabled={
              isAddingRole === rolesListItem.id &&
              roleAddingIDOfAdmin === adminID
            }
          />
        )
      })}
    </RoutesWrapper>
  )
}

const RoutesWrapper = styled.div`
  ${ROW_ALIGN_START__JUSTIFY_START}
  flex-wrap: wrap;
  gap: 10px;
  padding: 16px;
  ${mediaQueries.lessThan('xs')`
    padding: 8px;
    gap: 8px;
  `}
`
const ChipStyle = styled(Chip)`
  ${mediaQueries.lessThan('xs')`
 .MuiChip-label{
    padding: 0 8px;
    font-size: 10px;
 }
`}
`
