import { ColumnOfTable } from 'app/components/table/sortableTable'
import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'

export const RECORDS_TABLE_KEY = 'recordsTable'

export const columnsForShowInRecordsTable: ColumnOfTable[] = [
  { id: 'failureType', label: 'Failure Type' },
  { id: 'userCoreID', label: 'User CoreID' },
  { id: 'orderID', label: 'Order ID' },
  { id: 'txHash', label: 'TxHash' },
  { id: 'clientDomainUUID', label: 'Client DomainUUID' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updatedAt', label: 'Updated At', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${RECORDS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${RECORDS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${RECORDS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000

export const FAILURE_TYPE_OPTIONS = [
  'INVALID_CONFIRM_DATA',
  'FAILED_TO_MINED',
  'UNKNOWN',
  'PENDING_FOR_LONG_TIME',
]
