import { Box, Modal } from '@mui/material'

import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { rolesActions } from '../../slice'
import { rolesSelectors } from '../../selectors'
import { TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import {
  COLUMN_ALIGN_END__JUSTIFY_CENTER,
  COLUMN_ALIGN_END__JUSTIFY_START,
  COLUMN_ALIGN_START__JUSTIFY_START,
  ROW_ALIGN_CENTER__SPACE_B,
} from 'styles/globalStyles'
import { useFormik } from 'formik'
import { ExportButton } from 'app/components/button/exportButton'
import { mediaQueries } from 'styles/mediaQueries'
import BaseInput from 'app/components/input/baseInput'

export const AddRoleModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(rolesSelectors.addRoleModalDisplay)

  const handleModalClose = () => {
    dispatch(rolesActions.setAddRoleModalDisplay(false))
  }

  const handleAddClick = () => {
    dispatch(rolesActions.addRole(values))
    handleModalClose()
  }

  const initialValues = {
    code: '',
    name: '',
  }

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues,
    onSubmit: handleAddClick,
    validateOnChange: false,
  })

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalContainer>
        <StyledForm onSubmit={handleSubmit}>
          <Container>
            <TwoItemContainer>
              <RowContainer>
                <RowTitle>Code</RowTitle>
                <BaseInput
                  value={values.code}
                  fullWidth
                  name="code"
                  label="Code"
                  onChange={handleChange}
                />
              </RowContainer>
              <RowContainer>
                <RowTitle>Name</RowTitle>
                <BaseInput
                  type="text"
                  value={values.name}
                  fullWidth
                  name="name"
                  label="Name"
                  onChange={handleChange}
                />
              </RowContainer>
            </TwoItemContainer>
            <ExportButton type="submit">Add Role</ExportButton>
          </Container>
        </StyledForm>
      </ModalContainer>
    </Modal>
  )
}

const CModal = styled(Modal)`
  .MuiDialog-paperWidthSm {
    width: 608px;
    max-height: 921px;
    overflow: hidden;
  }

  .MuiDialog-paper {
    max-width: 608px;
    max-height: 921px;
    padding: 32px;
  }
`

const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  background-color: var(--white);
  border: none;
  box-shadow: 24px;
  padding: 32px;
  overflow: auto;
  ${mediaQueries.between('sm', 'md')`
  width:740px
  `}
  ${mediaQueries.lessThan('sm')`  
  width:540px;
  padding: 20px;
  `}
  ${mediaQueries.lessThan('xs')`
  width:300px
  `}
`

const StyledForm = styled('form')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap:24px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  gap: 16px;
  `}
`
const Container = styled('div')`
  ${COLUMN_ALIGN_END__JUSTIFY_CENTER}
  gap: 42px;
  width: 100%;
  height: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 32px;
  `}
  ${mediaQueries.lessThan('xs')`
  gap: 24px;
  `}
`

const RowContainer = styled('div')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  gap: 16px;
`

const RowTitle = styled('label')`
  ${TEXT_LG_SEMIBOLD}
`
const TwoItemContainer = styled('div')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
  gap: 24px;
  ${mediaQueries.lessThan('xs')` 
    flex-direction: column;
    gap: 16px;
  `}
`
