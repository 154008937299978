import { emailRejectionsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { emailRejectionsSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  MAX_SAFE_INTEGER,
  EMAIL_REJECTIONS_TABLE_KEY,
  columnsForShowInEmailRejectionsTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from '../types'
import { useLocation } from 'react-router-dom'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { AppPages } from 'app/constants'
import { redirectToBlockBox } from 'utils/redirections'

export const EmailRejectionsTable = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const emailRejectionsList = useSelector(
    emailRejectionsSelectors.emailRejectionsList,
  )
  const isLoadingEmailRejections = useSelector(
    emailRejectionsSelectors.isLoadingEmailRejections,
  )
  const filterEmailRejections = useSelector(
    emailRejectionsSelectors.filterDataEmailRejections,
  )
  const lastPageOfEmailRejectionsTable = useSelector(
    emailRejectionsSelectors.lastPageOfEmailRejectionsTable,
  )
  const isAllEmailRejectionsFetched = useSelector(
    emailRejectionsSelectors.isAllEmailRejectionsFetched,
  )

  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders
      const searchUserFilters = {
        ...filterEmailRejections,
        userCoreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(
        emailRejectionsActions.setFilterEmailRejections(searchUserFilters),
      )
    }
    dispatch(emailRejectionsActions.getEmailRejections())
  }, [searchUserCoreId])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${EMAIL_REJECTIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      emailRejectionsActions.setFilterEmailRejections({
        ...filterEmailRejections,
        pageSize: num,
      }),
    )
    dispatch(emailRejectionsActions.getEmailRejections())
  }

  return (
    <Wrapper>
      <SortableTable
        name={EMAIL_REJECTIONS_TABLE_KEY}
        isLoading={isLoadingEmailRejections}
        data={emailRejectionsList}
        columns={columnsForShowInEmailRejectionsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            emailRejectionsActions.setFilterEmailRejections({
              ...filterEmailRejections,
              page: newPage,
            }),
          )

          dispatch(emailRejectionsActions.getEmailRejections())
        }}
        setPage={page =>
          dispatch(
            emailRejectionsActions.setLastPageOfEmailRejectionsTable(page),
          )
        }
        page={lastPageOfEmailRejectionsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            emailRejectionsActions.setFilterEmailRejections({
              ...filterEmailRejections,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = emailRejectionsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(emailRejectionsActions.getEmailRejections())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllEmailRejectionsFetched}
        onCellClick={(row, column) => {
          if (column === 'txHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
