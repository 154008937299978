import * as React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Role } from 'app/containers/Roles/types'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'
import { rolesActions } from 'app/containers/Roles/slice'
import { RoutesList } from './routes-list'
import { rolesSelectors } from 'app/containers/Roles/selectors'
import { Button } from '@mui/material'
import { ExportButton } from 'app/components/button/exportButton'
import { mediaQueries } from 'styles/mediaQueries'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function RoleCard({ roleId }: { roleId: number | string }) {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState(false)
  const [displayRoutesList, setDisplayRoutesList] = React.useState(false)

  const role = useSelector(rolesSelectors.roleById(roleId))

  const handleExpandClick = () => {
    setDisplayRoutesList(false)
    setExpanded(!expanded)
  }

  if (!role) return <></>

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeaderStyles
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {role.name.charAt(0)}
          </Avatar>
        }
        title={role.name}
      />

      <StyledCardContent>
        <ItemRow>
          <ItemTitle>Id:</ItemTitle>
          <Typography variant="body2" color="text.secondary">
            {role.id}
          </Typography>
        </ItemRow>
        <ItemRow>
          <ItemTitle>Code:</ItemTitle>
          <Typography variant="body2" color="text.secondary">
            {role.code}
          </Typography>
        </ItemRow>
      </StyledCardContent>
      <CardActions disableSpacing>
        <ExpndMoreTitle>Routes</ExpndMoreTitle>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {displayRoutesList ? (
          <RoutesList roleId={role.id} />
        ) : (
          <StyledCardContent>
            {role.routeInfo?.map((route, index) => {
              return (
                <ItemRow key={index}>
                  <ItemTitle>{route.path}</ItemTitle>
                  <DeleteIconStyled
                    onClick={() => {
                      dispatch(
                        rolesActions.setDeleteConfirmationModalData({
                          display: true,
                          role,
                          route,
                        }),
                      )
                    }}
                  />
                </ItemRow>
              )
            })}
            <ExportButtonStyle onClick={() => setDisplayRoutesList(true)}>
              Add a new route to this role
            </ExportButtonStyle>
          </StyledCardContent>
        )}
      </Collapse>
    </Card>
  )
}

const DeleteIconStyled = styled(DeleteIcon)`
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`

const ItemRow = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // add gray shadow
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  padding: 4px;
`

const ItemTitle = styled('h1')`
  color: var(--causeway);
  font-size: 16px;
  ${mediaQueries.lessThan('sm')`
    font-size: 14px;
     margin:5px 0;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;`}
  ${mediaQueries.lessThan('xs')`
     font-size: 13px;
     `}
`

const FlexStartRow = styled(ItemRow)`
  justify-content: flex-start;
  gap: 14px;
`

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
  color: var(--causeway);
  ${mediaQueries.lessThan('sm')`
    gap: 10px;
     padding:4px
     `}
`

const ExpndMoreTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 700;
  color: var(--mirage);
  padding-left: 10px;
  ${mediaQueries.lessThan('sm')`
    font-size: 16px;
    `}
  ${mediaQueries.lessThan('xs')`
    font-size: 14px;`}
`
const ExportButtonStyle = styled(ExportButton)`
  width: 100%;
`
const CardHeaderStyles = styled(CardHeader)`
  && .css-sgoict-MuiCardHeader-action {
    margin: 0;
  }
  ${mediaQueries.lessThan('xs')`
    padding: 8px;
`}
`
