import { Box, Modal } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import {
  COLUMN_ALIGN_END__JUSTIFY_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_CENTER,
} from 'styles/globalStyles'
import { useFormik } from 'formik'
import { adminsSelectors } from '../../selectors'
import { adminsActions } from '../../slice'
import { ExportButton } from 'app/components/button/exportButton'
import BaseInput from 'app/components/input/baseInput'
import { DeleteForeverIcon } from 'app/components/icon/deleteForeverIcon'
import * as Yup from 'yup'
import { mediaQueries } from 'styles/mediaQueries'

export const AddAdminModal = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(adminsSelectors.addAdminModalDisplay)

  const handleModalClose = () => {
    dispatch(adminsActions.setAddAdminModalDisplay(false))
  }

  const handleAddClick = () => {
    dispatch(adminsActions.addAdmin(values))
    handleModalClose()
  }

  const initialValues = {
    coreID: '',
    fullName: '',
  }

  const validationSchema = Yup.object({
    coreID: Yup.string().required('Core ID is required'),
    fullName: Yup.string().required('Name is required'),
  })

  const { values, handleSubmit, handleChange, setFieldValue, touched, errors } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: handleAddClick,
    })

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <ModalContainer>
        <StyledForm onSubmit={handleSubmit}>
          <Container>
            <StyledContainer>
              <RowContainer>
                <RowTitle>Core ID</RowTitle>
                <BaseInput
                  fullWidth
                  type="text"
                  id="coreId"
                  name="coreID"
                  label="Core ID"
                  onChange={handleChange}
                  value={values?.coreID}
                  error={Boolean(touched.coreID && errors.coreID)}
                  helperText={touched.coreID && errors.coreID}
                  InputProps={{
                    endAdornment: values?.coreID ? (
                      <DeleteForeverIcon
                        onClick={() => {
                          setFieldValue('coreID', '')
                        }}
                      />
                    ) : undefined,
                  }}
                />
              </RowContainer>
              <RowContainer>
                <RowTitle>Name</RowTitle>
                <BaseInput
                  fullWidth
                  type="text"
                  id="fullName"
                  name="fullName"
                  label="Name"
                  onChange={handleChange}
                  value={values?.fullName}
                  error={Boolean(touched.fullName && errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  InputProps={{
                    endAdornment: values?.fullName ? (
                      <DeleteForeverIcon
                        onClick={() => {
                          setFieldValue('fullName', '')
                        }}
                      />
                    ) : undefined,
                  }}
                />
              </RowContainer>
            </StyledContainer>
            <ExportButton type="submit">Add Admin</ExportButton>
          </Container>
        </StyledForm>
      </ModalContainer>
    </Modal>
  )
}

const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  background-color: var(--white);
  border: none;
  box-shadow: 24px;
  padding: 32px;
  overflow: auto;
  ${mediaQueries.between('sm', 'md')`
  width:740px
  `}
  ${mediaQueries.lessThan('sm')`  
  width:540px;
  padding: 20px;
  `}
  ${mediaQueries.lessThan('xs')`
  width:300px
  `}
`
const Container = styled('div')`
  ${COLUMN_ALIGN_END__JUSTIFY_CENTER}
  gap: 42px;
  width: 100%;
  height: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 32px;
  `}
  ${mediaQueries.lessThan('xs')`
  gap: 24px;
  `}
`

const StyledForm = styled('form')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap:24px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  gap: 16px;
  `}
`

const StyledContainer = styled('div')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap:24px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
  flex-direction: column;
    gap: 16px;
  `}
`

const RowContainer = styled('div')`
  ${ROW_CENTER}
  width: 100%;
  gap: 16px;
  ${mediaQueries.lessThan('sm')`
  gap: 12px;`}
`

const RowTitle = styled('label')`
  ${TEXT_LG_SEMIBOLD}
  min-width: 60px;
  text-align: left;
  ${mediaQueries.lessThan('sm')`
  min-width: 50px;
  font-size: 14px;`}
`
