import styled from 'styled-components'
import { mediaQueries } from 'styles/mediaQueries'

export const SearchUserContainer = styled('section')`
  padding: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
    padding: 10px;
    `}
`
