//for all styles of test scope

import { styled } from '@mui/material'
import { mediaQueries } from 'styles/mediaQueries'

export const Wrapper = styled('div')`
  padding: 32px;
  width: 100%;
  ${mediaQueries.lessThan('xs')`
    padding: 16px;`}
`
