//for all styles of test scope

import styled from 'styled-components'
import { ROW_ALIGN_CENTER__SPACE_B } from 'styles/globalStyles'

export const Wrapper = styled.div`
  padding: 32px;
  width: 100%;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: var(--black-knight);
`
export const TitleAndExportWrapper = styled.div`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width:100%;
`
