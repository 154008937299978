//FIXME: we must remove this folder and this is a templete

import { EmailRejectionsHeader } from './components/Header'
import { FilterEmailRejectionsModal } from './components/modals/filter'
import { EmailRejectionsTable } from './components/table'
import { Wrapper } from './styles'

interface Props {}

export function EmailRejections(props: Props) {
  return (
    <Wrapper>
      <EmailRejectionsHeader />
      <EmailRejectionsTable />
      <FilterEmailRejectionsModal />
    </Wrapper>
  )
}
