//for all styles of test scope

import styled from 'styled-components'
import {
  COLUMN_JUSTIFY_START__ALIGN_CENTER,
  ROW_ALIGN_CENTER__SPACE_B,
} from 'styles/globalStyles'
import { mediaQueries } from 'styles/mediaQueries'

export const Wrapper = styled.div`
  padding: 32px;
  width: 100%;
  ${COLUMN_JUSTIFY_START__ALIGN_CENTER}
  gap: 24px;
  ${mediaQueries.lessThan('sm')`
    padding: 16px;
    gap: 16px;
    `}
`

export const TitleWrapper = styled.div`
  width: 100%;
  ${ROW_ALIGN_CENTER__SPACE_B}
`

export const TabTitle = styled.h1`
  ${mediaQueries.lessThan('md')`
    font-size: 28px;
    line-height: 32px;
    `}
  ${mediaQueries.lessThan('sm')`
    font-size: 24px;
    line-height: 28px;
    margin:10px 0;
    `}
`

export const AdminsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
  width: 100%;
  gap: 1rem;
  ${mediaQueries.lessThan('xs')`
   grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
   gap: 0.5rem;
  `}
`
