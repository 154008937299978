import { botOrdersActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { botOrdersSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  MAX_SAFE_INTEGER,
  BOT_ORDERS_TABLE_KEY,
  columnsForShowInBotOrdersTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from '../types'

export const BotOrdersTable = () => {
  const dispatch = useDispatch()

  const botOrders = useSelector(botOrdersSelectors.botOrderList)
  const isLoadingBotOrders = useSelector(botOrdersSelectors.isLoadingBotOrders)
  const filterBotOrders = useSelector(botOrdersSelectors.filterBotOrders)
  const lastPageOfBotOrdersTable = useSelector(
    botOrdersSelectors.lastPageOfBotOrdersTable,
  )
  const isAllBotOrdersFetched = useSelector(
    botOrdersSelectors.isAllBotOrdersFetched,
  )

  useEffect(() => {
    dispatch(botOrdersActions.getBotOrders())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${BOT_ORDERS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      botOrdersActions.setFilterBotOrders({
        ...filterBotOrders,
        pageSize: num,
      }),
    )
    dispatch(botOrdersActions.getBotOrders())
  }
  return (
    <Wrapper>
      <SortableTable
        name={BOT_ORDERS_TABLE_KEY}
        isLoading={isLoadingBotOrders}
        data={botOrders}
        columns={columnsForShowInBotOrdersTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            botOrdersActions.setFilterBotOrders({
              ...filterBotOrders,
              page: newPage,
            }),
          )

          dispatch(botOrdersActions.getBotOrders())
        }}
        setPage={page =>
          dispatch(botOrdersActions.setLastPageOfBotOrdersTable(page))
        }
        page={lastPageOfBotOrdersTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            botOrdersActions.setFilterBotOrders({
              ...filterBotOrders,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = botOrders[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(botOrdersActions.getBotOrders())
            }
          }
        }}
        countOfData={MAX_SAFE_INTEGER}
        showNumberOfPages={false}
        disableNextButton={isAllBotOrdersFetched}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
