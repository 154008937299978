import { useDispatch, useSelector } from 'react-redux'
import { nftsSelectors } from '../../../selectors'
import { nftsActions } from '../../../slice'
import styled from 'styled-components'
import { Modal } from '@mui/material'
import { ConfrimRejectModalContent } from './content'
import { VoucherNames } from 'app/containers/Vouchers/components/issueVoucherButton/constants'
import { mediaQueries } from 'styles/mediaQueries'

export const ConfirmDeleteModal = () => {
  const dispatch = useDispatch()

  const confirmRejectModalItem = useSelector(
    nftsSelectors.confirmRejectModalItem,
  )

  const handleModalClose = () =>
    dispatch(nftsActions.setConfirmRejectModalItem(undefined))

  return (
    <Modal open={!!confirmRejectModalItem} onClose={handleModalClose}>
      <Container>
        <ConfrimRejectModalContent />
      </Container>
    </Modal>
  )
}

const Container = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  background-color: var(--white);
  border: none;
  box-shadow: 24px;
  padding: 32px;
  overflow: auto;
  ${mediaQueries.between('sm', 'md')`
  width:750px
  `}
  ${mediaQueries.lessThan('sm')`  
  width:550px;
  padding: 20px;
  `}
  ${mediaQueries.lessThan('xs')`
  width:300px
  `}
`
