import { ColumnOfTable } from 'app/components/table/sortableTable'

export const TABLE_KEY = 'submissonsTable'

export const columnsForShowInTable: ColumnOfTable[] = [
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'transactionHash',
    label: 'Transaction Hash',
  },
  {
    id: 'timestamp',
    label: 'Date',
    isDate: true,
  },
  {
    id: 'action',
    label: 'Invalidate',
    actionKey: 'transactionHash',
  },
]

export const MAX_SAFE_INTEGER = 10000
