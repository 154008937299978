import { ColumnOfTable } from 'app/components/table/sortableTable'
import {
  capitalize,
  capitalizeAllCharacters,
  parseToNumber,
} from 'utils/string'
import { ISortType } from './types'

export const VOUCHERS_TABLE_KEY = 'vouchersTable'

export const columnsForShowInVouchersTable: ColumnOfTable[] = [
  { label: 'Admin', id: 'admin' },
  { label: 'User CoreID', id: 'userCoreID' },
  { label: 'Voucher Name', id: 'voucherName' },
  { label: 'Status', id: 'status' },
  { label: 'Amount', id: 'amount' },
  { label: 'BctxID', id: 'bctxID' },
  { label: 'TxHash', id: 'txHash' },
  { label: 'Created At', id: 'createdAt', isDate: true },
  { label: 'Updated At', id: 'updatedAt', isDate: true },
]

export const MAX_SAFE_INTEGER = 10000

export const localPageSize = parseToNumber(
  localStorage.getItem(`${VOUCHERS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${VOUCHERS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${VOUCHERS_TABLE_KEY}-orderBy`) ?? undefined

//FIXME:update this when we have more status
export const STATUS_OPTIONS_OF_VOUCHERS = ['SUCCESSFUL', 'IN_PROGRESS']
