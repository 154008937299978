import { ColumnOfTable } from 'app/components/table/sortableTable'
import {
  capitalize,
  capitalizeAllCharacters,
  parseToNumber,
} from 'utils/string'
import { ISortType } from './types'

export const ORDERS_TABLE_KEY = 'ordersTable'

export const columnsForShowInOrdersTable: ColumnOfTable[] = [
  {
    id: 'coreID',
    label: 'CoreID',
  },
  {
    id: 'productName',
    label: 'Product Name',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'orderId',
    label: 'Order ID',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'realAmount',
    label: 'Real Amount',
  },
  {
    id: 'value',
    label: 'Value',
  },
  {
    id: 'realValue',
    label: 'Real Value',
  },
  {
    id: 'feePercentage',
    label: 'Fee Percentage',
  },
  {
    id: 'finalAmount',
    label: 'Final Amount',
  },
  {
    id: 'finalRealAmount',
    label: 'Final Real Amount',
  },
  {
    id: 'tokenValue',
    label: 'Token Value',
  },
  {
    id: 'realTokenValue',
    label: 'Real Token Value',
  },
  {
    id: 'txHash',
    label: 'TxHash',
  },
  {
    id: 'bcTxID',
    label: 'BcTxID',
  },
  {
    id: 'retry',
    label: 'Retry',
  },
  {
    id: 'hashUpdatedAt',
    label: 'Hash Updated At',
    isDate: true,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isDate: true,
  },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${ORDERS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${ORDERS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${ORDERS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000

export const STATUS_OF_ORDERS = ['SUCCESSFUL', 'NEWLY_ADDED', 'UNKNOWN_FAILED']
