import { clientsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientsSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  MAX_SAFE_INTEGER,
  CLIENTS_TABLE_KEY,
  columnsForShowInClientsTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from '../types'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const clientsList = useSelector(clientsSelectors.clientsList)
  const isLoadingClients = useSelector(clientsSelectors.isLoadingClients)
  const filterClients = useSelector(clientsSelectors.filterClients)
  const lastPageOfClientsTable = useSelector(
    clientsSelectors.lastPageOfClientsTable,
  )
  const isAllClientsFetched = useSelector(clientsSelectors.isAllClientsFetched)

  useEffect(() => {
    dispatch(clientsActions.getClientsList())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${CLIENTS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      clientsActions.setFilterClients({
        ...filterClients,
        pageSize: num,
      }),
    )
    dispatch(clientsActions.getClientsList())
  }

  return (
    <Wrapper>
      <SortableTable
        name={CLIENTS_TABLE_KEY}
        isLoading={isLoadingClients}
        data={clientsList}
        columns={columnsForShowInClientsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            clientsActions.setFilterClients({
              ...filterClients,
              page: newPage,
            }),
          )

          dispatch(clientsActions.getClientsList())
        }}
        setPage={page =>
          dispatch(clientsActions.setLastPageOfClientsTable(page))
        }
        page={lastPageOfClientsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            clientsActions.setFilterClients({
              ...filterClients,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = clientsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(clientsActions.getClientsList())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllClientsFetched}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
