export enum LocalStorageKeys {
  CONNECTED_TO_WALLET_ONCE = 'CONNECTED_TO_WALLET_ONCE',
  AUTH_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN',
  SIDEBAR_OPEN_CATEGORY = 'SIDEBAR_OPEN_CATEGORY',
}

export enum SessionStorageKeys {}

export const storage = {
  write: (key: LocalStorageKeys, data: any) => {
    localStorage[key] = data
  },
  delete: (key: LocalStorageKeys) => {
    localStorage.removeItem(key)
  },
  clear: () => {
    localStorage.clear()
  },
  read: (key: LocalStorageKeys, ifDoesntExist?: any) => {
    try {
      return JSON.parse(localStorage[key])
    } catch (error) {
      if (ifDoesntExist) {
        localStorage[key] = JSON.stringify(ifDoesntExist)
        return ifDoesntExist
      }
      return null
    }
  },
  sessionStorage: {
    write: (key: SessionStorageKeys, data: any) => {
      sessionStorage[key] = JSON.stringify(data)
    },
    read: (key: SessionStorageKeys) => {
      return JSON.parse(sessionStorage[key])
    },
    localStorage: {
      write: (key: SessionStorageKeys, data: any) => {
        sessionStorage[key] = JSON.stringify(data)
      },
      read: (key: SessionStorageKeys) => {
        return JSON.parse(sessionStorage[key])
      },
    },
  },
}
