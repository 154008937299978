import { runSaga } from './configureStore'
import { resubmissionSaga } from 'app/containers/Resubmissions/saga'
import { resubmissionItemSaga } from 'app/containers/ResubmissionItem/saga'
import { rolesSaga } from 'app/containers/Roles/saga'
import { adminsSaga } from 'app/containers/Admins/saga'
import { mainPageSaga } from 'app/containers/MainPage/saga'
import { globalSaga } from './saga'
import { ordersSaga } from 'app/containers/Orders/saga'
import { paymentsSaga } from 'app/containers/Payments/saga'
import { vouchersSaga } from 'app/containers/Vouchers/saga'
import { emailVerificationsSaga } from 'app/containers/EmailVerifications/saga'
import { phoneVerificationsSaga } from 'app/containers/PhoneVerifications/saga'
import { phoneInvalidationsSaga } from 'app/containers/PhoneInvalidations/saga'
import { emailInvalidationsSaga } from 'app/containers/EmailInvalidations/saga'
import { documentInvalidationsSaga } from 'app/containers/DocumentInvalidations/saga'
import { submissionsSaga } from 'app/containers/Submissions/saga'
import { phoneRejectionsSaga } from 'app/containers/PhoneRejections/saga'
import { documentRejectionsSaga } from 'app/containers/DocumentRejections/saga'
import { transmissionsSaga } from 'app/containers/Transmissions/saga'
import { recordsSaga } from 'app/containers/Records/saga'
import { nftsSaga } from 'app/containers/Nfts/saga'
import { emailRejectionsSaga } from 'app/containers/EmailRejections/saga'
import { apiKeysSaga } from 'app/containers/ApiKeys/saga'
import { clientsSaga } from 'app/containers/Clients/saga'
import { clientSourceKeysSaga } from 'app/containers/ClientSourceKeys/saga'
import { oauthsSaga } from 'app/containers/Oauths/saga'
import { organizationsSaga } from 'app/containers/Organizations/saga'
import { domainsSaga } from 'app/containers/Domains/saga'
import { assetsManagementSaga } from 'app/containers/AssetsManagement/saga'
import { configurationsSaga } from 'app/containers/Configurations/saga'
import { botOrdersSaga } from 'app/containers/BotOrders/saga'

export const runSagas = () => {
  runSaga(mainPageSaga)
  runSaga(resubmissionSaga)
  runSaga(resubmissionItemSaga)
  runSaga(rolesSaga)
  runSaga(adminsSaga)
  runSaga(globalSaga)
  runSaga(paymentsSaga)
  runSaga(ordersSaga)
  runSaga(vouchersSaga)
  runSaga(emailVerificationsSaga)
  runSaga(phoneVerificationsSaga)
  runSaga(phoneInvalidationsSaga)
  runSaga(emailInvalidationsSaga)
  runSaga(documentInvalidationsSaga)
  runSaga(submissionsSaga)
  runSaga(phoneRejectionsSaga)
  runSaga(documentRejectionsSaga)
  runSaga(emailRejectionsSaga)
  runSaga(transmissionsSaga)
  runSaga(recordsSaga)
  runSaga(nftsSaga)
  runSaga(apiKeysSaga)
  runSaga(clientsSaga)
  runSaga(clientSourceKeysSaga)
  runSaga(oauthsSaga)
  runSaga(organizationsSaga)
  runSaga(domainsSaga)
  runSaga(assetsManagementSaga)
  runSaga(configurationsSaga)
  runSaga(botOrdersSaga)
}
