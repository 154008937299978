import { useDispatch, useSelector } from 'react-redux'
import { paymentsActions } from '../slice'
import { ThreeDotLoadingIndicator } from 'app/components/common/loading/ThreeDotLoadingIndicator/threeDotLoading'
import { styled } from '@mui/material'
import SortableTable from 'app/components/table/sortableTable'
import { paymentsSelectors } from '../selectors'
import { useEffect } from 'react'
import {
  MAX_SAFE_INTEGER,
  PAYMENTS_TABLE_KEY,
  columnsForShowInPaymentsTable,
} from '../constants'
import { ISortType } from '../types'
import { capitalizeAllCharacters } from 'utils/string'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { useLocation } from 'react-router-dom'
import { AppPages } from 'app/constants'

export const PaymentsTable = () => {
  const dispatch = useDispatch()

  const isLoadingPayments = useSelector(paymentsSelectors.isLoadingPayments)
  const paymentsList = useSelector(paymentsSelectors.paymentList)
  const filterPayments = useSelector(paymentsSelectors.filterPayments)
  const lastPageOfPaymentsTable = useSelector(
    paymentsSelectors.lastPageOfPaymentsTable,
  )
  const isAllPaymentsFetched = useSelector(
    paymentsSelectors.isAllPaymentsFetched,
  )
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders
      const searchUserFilters = {
        ...filterPayments,
        coreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(paymentsActions.setFilterPayments(searchUserFilters))
    }
    dispatch(paymentsActions.getPayments())
  }, [searchUserCoreId])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${PAYMENTS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      paymentsActions.setFilterPayments({
        ...filterPayments,
        pageSize: num,
      }),
    )

    dispatch(paymentsActions.getPayments())
  }

  return (
    <Wrapper>
      <SortableTable
        name={PAYMENTS_TABLE_KEY}
        isLoading={isLoadingPayments}
        data={paymentsList}
        columns={columnsForShowInPaymentsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            paymentsActions.setFilterPayments({
              ...filterPayments,
              page: newPage,
            }),
          )

          dispatch(paymentsActions.getPayments())
        }}
        setPage={page =>
          dispatch(paymentsActions.setLastPageOfPaymentsTable(page))
        }
        page={lastPageOfPaymentsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, payment) => {
          dispatch(
            paymentsActions.setFilterPayments({
              ...filterPayments,
              sortField: property,
              sortType: capitalizeAllCharacters(payment) as ISortType,
            }),
          )
          const oneRow = paymentsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(paymentsActions.getPayments())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllPaymentsFetched}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
