import { ColumnOfTable } from 'app/components/table/sortableTable'
import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'

export const columnsForShowInResubmissionsTable: ColumnOfTable[] = [
  {
    id: 'coreId',
    label: 'CoreID',
  },
  {
    id: 'userReviewStatus',
    label: 'User Status',
  },
  {
    id: 'providerStatus',
    label: 'Sumsub Status',
  },
  {
    id: 'vouchererName',
    label: 'Voucherer Name',
  },
  {
    id: 'referenceId',
    label: 'Sumsub Link',
  },
  {
    id: 'deniedCount',
    label: 'Denied Count',
  },
  {
    id: 'ProviderUpdateRetryCount',
    label: 'Provider Update Retry Count',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isDate: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    isDate: true,
  },
]

export const RESUBMISSIONS_TABLE_KEY = 'resubmissionsTable'

export const ORDER_OF_SORT_IN_SUMSUB_STATUS = [
  'OFFICER_PROVIDER_PENDING',
  'KYC_PROVIDER_PENDING',
  'OFFICER_PROVIDER_REJECTED',
  'KYC_PROVIDER_REJECTED',
  'OFFICER_PROVIDER_CONFIRMED',
  'KYC_PROVIDER_CONFIRMED',
]

export const ORDER_OF_SORT_IN_USER_STATUS = ['REJECTED', 'PENDING', 'CONFIRMED']

export const localPageSize = parseToNumber(
  localStorage.getItem(`${RESUBMISSIONS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${RESUBMISSIONS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${RESUBMISSIONS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000
