import { phoneInvalidationsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { phoneInvalidationsSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  MAX_SAFE_INTEGER,
  PHONE_INVALIDATIONS_TABLE_KEY,
  columnsForShowInPhoneInvalidationsTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from '../types'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { useLocation } from 'react-router-dom'
import { AppPages } from 'app/constants'
import { redirectToBlockBox } from 'utils/redirections'

export const PhoneInvalidationsTable = () => {
  const dispatch = useDispatch()

  const phoneInvalidationList = useSelector(
    phoneInvalidationsSelectors.phoneInvalidationList,
  )
  const isLoadingPhoneInvalidations = useSelector(
    phoneInvalidationsSelectors.isLoadingPhoneInvalidations,
  )
  const filterPhoneInvalidations = useSelector(
    phoneInvalidationsSelectors.filterPhoneInvalidations,
  )
  const lastPageOfPhoneInvalidationsTable = useSelector(
    phoneInvalidationsSelectors.lastPageOfPhoneInvalidationsTable,
  )
  const isAllPhoneInvalidationsFetched = useSelector(
    phoneInvalidationsSelectors.isAllPhoneInvalidationsFetched,
  )
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders
      const searchUserFilters = {
        ...filterPhoneInvalidations,
        userCoreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(
        phoneInvalidationsActions.setFilterPhoneInvalidations(
          searchUserFilters,
        ),
      )
    }
    dispatch(phoneInvalidationsActions.getPhoneInvalidations())
  }, [searchUserCoreId])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${PHONE_INVALIDATIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      phoneInvalidationsActions.setFilterPhoneInvalidations({
        ...filterPhoneInvalidations,
        pageSize: num,
      }),
    )
    dispatch(phoneInvalidationsActions.getPhoneInvalidations())
  }

  return (
    <Wrapper>
      <SortableTable
        name={PHONE_INVALIDATIONS_TABLE_KEY}
        isLoading={isLoadingPhoneInvalidations}
        data={phoneInvalidationList}
        columns={columnsForShowInPhoneInvalidationsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            phoneInvalidationsActions.setFilterPhoneInvalidations({
              ...filterPhoneInvalidations,
              page: newPage,
            }),
          )

          dispatch(phoneInvalidationsActions.getPhoneInvalidations())
        }}
        setPage={page =>
          dispatch(
            phoneInvalidationsActions.setLastPageOfPhoneInvalidationsTable(
              page,
            ),
          )
        }
        page={lastPageOfPhoneInvalidationsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            phoneInvalidationsActions.setFilterPhoneInvalidations({
              ...filterPhoneInvalidations,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = phoneInvalidationList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(phoneInvalidationsActions.getPhoneInvalidations())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllPhoneInvalidationsFetched}
        onCellClick={(row, column) => {
          if (column === 'txHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
