import { useDispatch, useSelector } from 'react-redux'
import BaseInput from 'app/components/input'
import { useFormik } from 'formik'
import * as yup from 'yup'
import styled from 'styled-components'
import { COLUMN_ALIGN_START__SPACE_B } from 'styles/globalStyles'
import { ExportButton } from 'app/components/button/exportButton'
import CustomTooltip from 'app/components/tooltip'
import { nftsSelectors } from 'app/containers/Nfts/selectors'
import { nftsActions } from 'app/containers/Nfts/slice'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { mediaQueries } from 'styles/mediaQueries'

export const ConfrimRejectModalContent = () => {
  const dispatch = useDispatch()
  const confirmRejectModalItem = useSelector(
    nftsSelectors.confirmRejectModalItem,
  )
  const userCoreID = useSelector(searchUserSelectors.coreId)
  const isRejectingNft = useSelector(nftsSelectors.isRejectingNft)

  const initialValues = {
    reason: '',
    voucherName: confirmRejectModalItem?.type,
    userCoreID,
    nftId: confirmRejectModalItem?.id,
  }

  const onSubmit = () => {
    if (values?.voucherName) {
      //@ts-ignore
      dispatch(nftsActions.rejectNft(values))
    }
  }

  const { values, handleChange, handleSubmit, errors, handleBlur } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    initialValues,
    validationSchema: yup.object().shape({
      reason: yup
        .string()
        .required('reason is required')
        .max(250, 'Maximum reason length is 250'),
    }),
    onSubmit,
  })

  return (
    <FormContainer onSubmit={handleSubmit}>
      <ItemConatiner>
        <ItemLabel>Reason:</ItemLabel>
        <StyledBaseInput
          fullWidth
          type="text"
          id="reason"
          name="reason"
          placeholder="Enter the reason"
          onChange={e => handleChange(e)}
          value={values.reason}
          onBlur={handleBlur}
          multiline
        />
      </ItemConatiner>
      <CTAContainer>
        <CustomTooltip message={errors?.reason || ''}>
          <ExportButton
            isLoading={isRejectingNft}
            type="submit"
            disabled={!!errors?.reason}
          >
            Submit
          </ExportButton>
        </CustomTooltip>
      </CTAContainer>
    </FormContainer>
  )
}

const ItemConatiner = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`

const ItemLabel = styled('div')`
  font-size: 14px;
  font-weight: 500;
  word-wrap: normal;
  min-width: 120px;
  font-weight: 700;
  ${mediaQueries.lessThan('sm')`
    font-size: 12px;
    `}
`

const FormContainer = styled('form')`
  overflow: auto;
  ${COLUMN_ALIGN_START__SPACE_B}
  height: 100%;
  gap: 8px;
`

const CTAContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  margin-top: 28px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  margin-top: 16px;`}
  ${mediaQueries.lessThan('xs')`
  margin-top: 8px;`}
`

const StyledBaseInput = styled(BaseInput)`
  .MuiInputBase-inputMultiline {
    padding: 8px;
  }
`
