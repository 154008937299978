import { ColumnOfTable } from 'app/components/table/sortableTable'
import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'

export const CLIENT_SOURCE_KEYS_TABLE_KEY = 'clientSourceKeysTable'

export const columnsForShowInClientSourceKeysTable: ColumnOfTable[] = [
  { id: 'organizationID', label: 'OrganizationID' },
  { id: 'clientDomainID', label: 'Client DomainID' },
  { id: 'clientDomainUUID', label: 'Client DomainUUID' },
  { id: 'dataStoreZone', label: 'Data Store Zone' },
  { id: 'initialQuantity', label: 'Initial Quantity' },
  { id: 'notifyCount', label: 'Notify Count' },
  { id: 'fields', label: 'Fields' },
  { id: 'optionalFields', label: 'Optional Fields' },
  { id: 'chargeAmount', label: 'Charge Amount' },
  { id: 'plan', label: 'Plan' },
  { id: 'sourceKey', label: 'Source Key' },
  { id: 'totalQuantity', label: 'Total Quantity' },
  { id: 'webhookSecretKey', label: 'Webhook SecretKey' },
  { id: 'wentToKyc', label: 'Went To Kyc' },
  { id: 'renewalDate', label: 'Renewal Date', isDate: true },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updateAt', label: 'Updated At', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${CLIENT_SOURCE_KEYS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${CLIENT_SOURCE_KEYS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${CLIENT_SOURCE_KEYS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000

export const PLAN_OPTIONS = ['BUNDLE', 'CHARGE']
