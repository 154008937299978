import { css, styled } from '@mui/material'
import { PrimaryButton } from 'app/components/common/buttons/primary'

export const ExportButton = styled(PrimaryButton)<{ disabled?: boolean }>`
  ${props => {
    if (props.disabled) {
      return css`
        background-color: grey !important;
      `
    }
  }}
  width: ${props => (props.fullWidth ? '100%' : '148px')};
`
