import { CustomButtonProps } from 'app/components/types'
import { FC } from 'react'
import styled, { css } from 'styled-components'
import { BUTTON } from 'styles/globalStyles'
import { ThreeDotLoadingIndicator } from '../../loading/ThreeDotLoadingIndicator/threeDotLoading'
import { mediaQueries } from 'styles/mediaQueries'

export const PrimaryButton: FC<CustomButtonProps> = ({
  children,
  isLoading = false,
  ...props
}) => {
  return (
    <PrimaryButtonStyle isLoading={isLoading} {...props}>
      {isLoading ? <StyledThreeDotLoadingIndicator /> : children}
    </PrimaryButtonStyle>
  )
}

const StyledThreeDotLoadingIndicator = styled(ThreeDotLoadingIndicator)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn 2s !important;

  & > div > * {
    background: var(--white) !important;
    width: 12px !important;
    height: 12px !important;
  }
`

const PrimaryButtonStyle = styled.button<CustomButtonProps>`
  position: relative;

  ${props => {
    if (props.fullWidth) {
      return css`
        width: 100%;
      `
    }
    return
  }}

  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: var(--white);
  background: var(--denim);
  border-radius: 8px;
  ${BUTTON}
  padding:12px;
`
