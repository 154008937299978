import { SearchBar } from './components/search-bar'
import { TabContainer } from './components/tab-container'
import { SearchUserContainer } from './style'

interface Props {}

export function SearchUser(props: Props) {
  return (
    <SearchUserContainer>
      <SearchBar />
      <TabContainer />
    </SearchUserContainer>
  )
}
