import { ColumnOfTable } from 'app/components/table/sortableTable'
import {
  capitalize,
  capitalizeAllCharacters,
  parseToNumber,
} from 'utils/string'
import { ISortType } from './types'

export const ASSETS_MANAGEMENT_TABLE_KEY = 'assetsManagementTable'

export const columnsForShowInAssetsManagementTable: ColumnOfTable[] = [
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'asset',
    label: 'Asset',
  },
  {
    id: 'fee',
    label: 'Fee',
  },
  {
    id: 'maintenanceAmount',
    label: 'Maintenance Amount',
  },
  {
    id: 'maintenanceFee',
    label: 'Maintenance Fee',
  },
  {
    id: 'period',
    label: 'Period',
  },
  {
    id: 'price',
    label: 'Price',
  },
  {
    id: 'priceIncludingFee',
    label: 'Price Including Fee',
  },
  {
    id: 'priceIncludingMaintenanceFee',
    label: 'Price Including Maintenance Fee',
  },
  {
    id: 'profit',
    label: 'Profit',
  },
  {
    id: 'profitPercentage',
    label: 'Profit Percentage',
  },
  {
    id: 'startedAt',
    label: 'Started At',
    isDate: true,
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'totalSales',
    label: 'Total Sales',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isDate: true,
  },
  {
    id: 'endedAt',
    label: 'Ended At',
    isDate: true,
  },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${ASSETS_MANAGEMENT_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${ASSETS_MANAGEMENT_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${ASSETS_MANAGEMENT_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000

export const STATUS_OPTIONS = ['CREATED', 'AGGREGATED']
