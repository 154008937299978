//FIXME: we must remove this folder and this is a templete

import { Wrapper } from './styles'
import { TableOfData } from './components/table'
import { Header } from './components/Header'
import { FilterResubmissionsModal } from './components/modals/filter'

export function Resubmissions() {
  return (
    <Wrapper>
      <Header />
      <TableOfData />
      <FilterResubmissionsModal />
    </Wrapper>
  )
}
