import { ColumnOfTable } from 'app/components/table/sortableTable'
import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'

export const BOT_ORDERS_TABLE_KEY = 'botOrdersTable'

export const columnsForShowInBotOrdersTable: ColumnOfTable[] = [
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'assetManagementID',
    label: 'Asset Management ID',
  },
  {
    id: 'failureReason',
    label: 'Failure Reason',
  },
  {
    id: 'metaData',
    label: 'Meta Data',
  },
  {
    id: 'metaID',
    label: 'Meta ID',
  },
  {
    id: 'price',
    label: 'Price',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'symbol',
    label: 'Symbol',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isDate: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated At',
    isDate: true,
  },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${BOT_ORDERS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${BOT_ORDERS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${BOT_ORDERS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000

export const STATUS_OPTIONS = ['IN_PROGRESS', 'CANCELED', 'FAILED', 'COMPLETED']
