import { clientSourceKeysActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clientSourceKeysSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  MAX_SAFE_INTEGER,
  CLIENT_SOURCE_KEYS_TABLE_KEY,
  columnsForShowInClientSourceKeysTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from '../types'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const clientSourceKeysList = useSelector(
    clientSourceKeysSelectors.clientSourceKeysList,
  )
  const isLoadingClientSourceKeys = useSelector(
    clientSourceKeysSelectors.isLoadingClientSourceKeys,
  )
  const filterClientSourceKeys = useSelector(
    clientSourceKeysSelectors.filterClientSourceKeys,
  )
  const lastPageOfClientSourceKeysTable = useSelector(
    clientSourceKeysSelectors.lastPageOfClientSourceKeysTable,
  )
  const isAllClientSourceKeysFetched = useSelector(
    clientSourceKeysSelectors.isAllClientSourceKeysFetched,
  )

  useEffect(() => {
    dispatch(clientSourceKeysActions.getClientSourceKeysList())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${CLIENT_SOURCE_KEYS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      clientSourceKeysActions.setFilterClientSourceKeys({
        ...filterClientSourceKeys,
        pageSize: num,
      }),
    )
    dispatch(clientSourceKeysActions.getClientSourceKeysList())
  }

  return (
    <Wrapper>
      <SortableTable
        name={CLIENT_SOURCE_KEYS_TABLE_KEY}
        isLoading={isLoadingClientSourceKeys}
        data={clientSourceKeysList}
        columns={columnsForShowInClientSourceKeysTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            clientSourceKeysActions.setFilterClientSourceKeys({
              ...filterClientSourceKeys,
              page: newPage,
            }),
          )

          dispatch(clientSourceKeysActions.getClientSourceKeysList())
        }}
        setPage={page =>
          dispatch(
            clientSourceKeysActions.setLastPageOfClientSourceKeysTable(page),
          )
        }
        page={lastPageOfClientSourceKeysTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            clientSourceKeysActions.setFilterClientSourceKeys({
              ...filterClientSourceKeys,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = clientSourceKeysList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(clientSourceKeysActions.getClientSourceKeysList())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllClientSourceKeysFetched}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
