import { PaymentsTable } from './components/PaymentsTable'
import { Wrapper } from './styles'
import { Header } from './components/Header'
import { FilterPaymentsModal } from './components/modals/filter'

interface Props {}

export function Payments(props: Props) {
  return (
    <Wrapper>
      <Header />
      <PaymentsTable />
      <FilterPaymentsModal />
    </Wrapper>
  )
}
