import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Modal, styled } from '@mui/material'
import { FilterApiKeysContent } from './content'
import { mediaQueries } from 'styles/mediaQueries'
import { apiKeysSelectors } from 'app/containers/ApiKeys/selectors'
import { apiKeysActions } from 'app/containers/ApiKeys/slice'

interface IFilterModalProps {}

export const FilterApiKeysModal: FC<IFilterModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(apiKeysSelectors.displayFilterApiKeysModal)
  const handleModalClose = () => {
    dispatch(apiKeysActions.setDisplayFilterApiKeysModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <Container>
        <FilterApiKeysContent />
      </Container>
    </Modal>
  )
}

const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  background-color: var(--white);
  border: none;
  box-shadow: 24px;
  padding: 32px;
  overflow: auto;
  ${mediaQueries.between('sm', 'md')`
  width:750px
  `}
  ${mediaQueries.lessThan('sm')`  
  width:550px;
  padding: 20px;
  `}
  ${mediaQueries.lessThan('xs')`
  width:300px
  `}
`
