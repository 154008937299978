//FIXME: we must remove this folder and this is a templete

import { PhoneRejectionsHeader } from './components/Header'
import { FilterPhoneRejectionsModal } from './components/modals/filter'
import { PhoneRejectionsTable } from './components/table'
import { Wrapper } from './styles'

interface Props {}

export function PhoneRejections(props: Props) {
  return (
    <Wrapper>
      <PhoneRejectionsHeader />
      <PhoneRejectionsTable />
      <FilterPhoneRejectionsModal />
    </Wrapper>
  )
}
