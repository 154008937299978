import { ColumnOfTable } from 'app/components/table/sortableTable'
import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'

export const DOMAINS_TABLE_KEY = 'domainsTable'

export const columnsForShowInDomainsTable: ColumnOfTable[] = [
  { id: 'name', label: 'Name' },
  { id: 'domain', label: 'Domain' },
  { id: 'framework', label: 'Framework' },
  { id: 'organizationID', label: 'OrganizationID' },
  { id: 'organizationUuid', label: 'Organization UUID' },
  { id: 'platform', label: 'Platform' },
  { id: 'status', label: 'Status' },
  { id: 'verifyCode', label: 'Verify Code' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updatedAt', label: 'Updated At', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${DOMAINS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${DOMAINS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${DOMAINS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000

export const FRAMEWORK_OPTIONS = [
  'ReactJS',
  'VueJS',
  'Angular',
  'Flutter',
  'ReactNative',
]

export const PLATFORM_OPTIONS = ['WEB', 'MOBILE']

export const STATUS_OPTIONS = ['VERIFIED', 'UNVERIFIED']
