import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'

import SortableTable from 'app/components/table/sortableTable'
import { emailVerificationsSelectors } from '../selectors'
import { emailVerificationsActions } from '../slice'
import {
  MAX_SAFE_INTEGER,
  EMAIL_VERIFICATIONS_TABLE_KEY,
  columnsForShowInEmailVerificationsTable,
} from '../constants'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from '../types'
import { useLocation } from 'react-router-dom'
import { searchUserSelectors } from 'app/containers/SearchUser/selector'
import { AppPages } from 'app/constants'
import { redirectToBlockBox } from 'utils/redirections'

export const EmailVerificationsTable = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const emailVerifications = useSelector(
    emailVerificationsSelectors.emailVerificationsList,
  )
  const isLoadingEmailVerifications = useSelector(
    emailVerificationsSelectors.isLoadingEmailVerifications,
  )
  const filterEmailVerifications = useSelector(
    emailVerificationsSelectors.filterEmailVerifications,
  )
  const lastPageOfEmailVerificationsTable = useSelector(
    emailVerificationsSelectors.lastPageOfEmailVerificationsTable,
  )
  const isAllEmailVerificationsFetched = useSelector(
    emailVerificationsSelectors.isAllEmailVerificationsFetched,
  )
  const searchUserCoreId = useSelector(searchUserSelectors.coreId)

  //add new line to each fingerprint
  const emailVerificationsList = emailVerifications.map(item => {
    if (item.fingerprints && Array.isArray(item.fingerprints)) {
      let updatedFingerprints = item.fingerprints.map(fp => fp + '\n')
      return { ...item, fingerprints: updatedFingerprints }
    } else {
      return item
    }
  })

  useEffect(() => {
    if (pathname === AppPages.SearchUser) {
      // add coreId to filterData if the user is coming from search user page
      // and the search term (coreId) is not empty
      // if the user is coming from search user page and the search term (coreId) is empty
      // remove coreId filter and get all orders
      const searchUserFilters = {
        ...filterEmailVerifications,
        coreID: searchUserCoreId ? searchUserCoreId : '',
      }
      dispatch(
        emailVerificationsActions.setFilterEmailVerifications(
          searchUserFilters,
        ),
      )
    }
    dispatch(emailVerificationsActions.getEmailVerifications())
  }, [searchUserCoreId])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${EMAIL_VERIFICATIONS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      emailVerificationsActions.setFilterEmailVerifications({
        ...filterEmailVerifications,
        pageSize: num.toString(),
      }),
    )
    dispatch(emailVerificationsActions.getEmailVerifications())
  }

  return (
    <Wrapper>
      <SortableTable
        name={EMAIL_VERIFICATIONS_TABLE_KEY}
        isLoading={isLoadingEmailVerifications}
        data={emailVerificationsList}
        columns={columnsForShowInEmailVerificationsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            emailVerificationsActions.setFilterEmailVerifications({
              ...filterEmailVerifications,
              page: newPage.toString(),
            }),
          )

          dispatch(emailVerificationsActions.getEmailVerifications())
        }}
        setPage={page =>
          dispatch(
            emailVerificationsActions.setLastPageOfEmailVerificationsTable(
              page,
            ),
          )
        }
        page={lastPageOfEmailVerificationsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, item) => {
          dispatch(
            emailVerificationsActions.setFilterEmailVerifications({
              ...filterEmailVerifications,
              sortField: property,
              sortType: capitalizeAllCharacters(item) as ISortType,
            }),
          )
          const oneRow = emailVerificationsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(emailVerificationsActions.getEmailVerifications())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllEmailVerificationsFetched}
        onCellClick={(row, column) => {
          if (column === 'txHash') {
            redirectToBlockBox(row[column])
          }
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
