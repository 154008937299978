import { FC } from 'react'
import { Box, InputBase, InputProps, Skeleton } from '@mui/material'
import { ROW_JUSTIFY_END__ALIGN_CENTER } from 'styles/globalStyles'
import styled from 'styled-components'

export interface BaseInputProps extends InputProps {
  isLoading?: boolean
}

const BaseInput: FC<BaseInputProps> = ({ isLoading = false, ...props }) => {
  return (
    <>
      {isLoading ? (
        <Container>
          <StyledSkeleton variant="rectangular" animation="wave" />
        </Container>
      ) : (
        <BInput {...props}></BInput>
      )}
    </>
  )
}

const BInput = styled(InputBase)<BaseInputProps>`
  width: 100%;
  color: white;
  text-align: right;
  background-color: var(--wash-me);
  padding: 5px;
  border-radius: 5px;
`

const Container = styled(Box)`
  width: 100%;
  ${ROW_JUSTIFY_END__ALIGN_CENTER}
  min-height: 43px;
`

const StyledSkeleton = styled(Skeleton)`
  background-color: var(--Neutrals-N800);
  width: 90px;
`

export default BaseInput
