import { oauthsActions } from '../slice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { oauthsSelectors } from '../selectors'
import { styled } from '@mui/material'
import {
  MAX_SAFE_INTEGER,
  OAUTHS_TABLE_KEY,
  columnsForShowInOauthsTable,
} from '../constants'
import SortableTable from 'app/components/table/sortableTable'
import { capitalizeAllCharacters } from 'utils/string'
import { ISortType } from '../types'

export const TableOfData = () => {
  const dispatch = useDispatch()

  const oauthsList = useSelector(oauthsSelectors.oauthsList)
  const isLoadingOauths = useSelector(oauthsSelectors.isLoadingOauths)
  const filterOauths = useSelector(oauthsSelectors.filterOauths)
  const lastPageOfOauthsTable = useSelector(
    oauthsSelectors.lastPageOfOauthsTable,
  )
  const isAllOauthsFetched = useSelector(oauthsSelectors.isAllOauthsFetched)

  useEffect(() => {
    dispatch(oauthsActions.getOauthsList())
  }, [])

  const handleSetNumberRowsPerPage = (num: number) => {
    const tablePageSizeTitle = `${OAUTHS_TABLE_KEY}_pageSize`
    localStorage.setItem(tablePageSizeTitle, num.toString())
    dispatch(
      oauthsActions.setFilterOauths({
        ...filterOauths,
        pageSize: num,
      }),
    )
    dispatch(oauthsActions.getOauthsList())
  }

  return (
    <Wrapper>
      <SortableTable
        name={OAUTHS_TABLE_KEY}
        isLoading={isLoadingOauths}
        data={oauthsList}
        columns={columnsForShowInOauthsTable}
        handleChangeLastPage={(newPage: number) => {
          dispatch(
            oauthsActions.setFilterOauths({
              ...filterOauths,
              page: newPage,
            }),
          )

          dispatch(oauthsActions.getOauthsList())
        }}
        setPage={page => dispatch(oauthsActions.setLastPageOfOauthsTable(page))}
        page={lastPageOfOauthsTable}
        setNumberRowsPerPage={num => handleSetNumberRowsPerPage(num)}
        handleCustomRequestSort={(property, order) => {
          dispatch(
            oauthsActions.setFilterOauths({
              ...filterOauths,
              sortField: property,
              sortType: capitalizeAllCharacters(order) as ISortType,
            }),
          )
          const oneRow = oauthsList[0]
          if (oneRow) {
            const propertyType = typeof oneRow[property as keyof typeof oneRow]
            if (propertyType === 'number') {
              dispatch(oauthsActions.getOauthsList())
            }
          }
        }}
        showNumberOfPages={false}
        countOfData={MAX_SAFE_INTEGER}
        disableNextButton={isAllOauthsFetched}
      />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  justify-content: center;
  grid-gap: 10px 0;
  margin-top: 20px;
  margin-bottom: 20px;
  min-width: 0;
`
