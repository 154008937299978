//FIXME: we must remove this folder and this is a templete

import { PhoneInvalidationsHeader } from './components/Header'
import { FilterPhoneInvalidationsModal } from './components/modals/filter'
import { PhoneInvalidationsTable } from './components/table'
import { Wrapper } from './styles'

interface Props {}

export function PhoneInvalidations(props: Props) {
  return (
    <Wrapper>
      <PhoneInvalidationsHeader />
      <PhoneInvalidationsTable />
      <FilterPhoneInvalidationsModal />
    </Wrapper>
  )
}
