import { Box, Button } from '@mui/material'
import { Modal } from 'app/components/modal'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { rolesActions } from '../../slice'
import { rolesSelectors } from '../../selectors'
import { H3_BOLD, TEXT_LG_SEMIBOLD } from 'styles/globalTypography'
import { COLUMN_CENTER } from 'styles/globalStyles'
import DeleteIcon from '@mui/icons-material/Delete'
import { ExportButton } from 'app/components/button/exportButton'
import { mediaQueries } from 'styles/mediaQueries'

export const DeleteConfirmationModal = () => {
  const dispatch = useDispatch()
  const {
    role,
    route,
    display: isOpen,
  } = useSelector(rolesSelectors.deleteConfirmationModal)
  const handleModalClose = () => {
    dispatch(
      rolesActions.setDeleteConfirmationModalData({
        display: false,
        role: null,
        route: null,
      }),
    )
  }

  const handleConfirmClick = () => {
    dispatch(rolesActions.deleteRouteFromRole())
    handleModalClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <Wrapper>
        <DeleteIcon
          color="error"
          sx={{
            width: {
              sm: '50px',
              xs: '32px',
            },
            height: {
              sm: '50px',
              xs: '32px',
            },
          }}
        />
        <Title>{'Do you want to delete this route?'}</Title>
        <Body>{route?.path}</Body>
        <ExportButton onClick={handleConfirmClick}>Confirm</ExportButton>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled(Box)`
  ${COLUMN_CENTER};
  gap: 32px;
  width: 100%;
  ${mediaQueries.lessThan('sm')`
  gap: 23px;
  `}
  ${mediaQueries.lessThan('xs')`
  gap: 16px;`}
`

const Title = styled('p')`
  ${H3_BOLD}
  margin: 0;
  ${mediaQueries.lessThan('sm')`
  font-size: 24px;
  `}
  ${mediaQueries.lessThan('xs')`
  font-size: 20px;`}
`

const Body = styled('p')`
  ${TEXT_LG_SEMIBOLD}
  margin: 0;
  ${mediaQueries.lessThan('sm')`
  font-size: 14px;
  `}
  ${mediaQueries.lessThan('xs')`
  font-size: 12px;`}
`
