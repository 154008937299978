import { useSearchParams } from 'react-router-dom'
import {
  HeaderContainer,
  HeaderTitle,
  Title,
  TitleAndExportWrapper,
  Wrapper,
} from './styles'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resubmissionItemActions } from './slice'
import { resubmissionItemSelectors } from './selectors'
import { IKYCProviderAddress, IKYCProviderData } from './types'
import * as Yup from 'yup'
import { getIn, useFormik } from 'formik'
import { doesAddressExist, isIKYCProviderAddress } from './providers/utils'
import { ResubmissionRow } from './components/ResubmissionRow'
import { customToast } from 'app/components/toast/CustomToast'
import { FIELD_ORDER } from './constants'
import { ExportButton } from 'app/components/button/exportButton'
import { PageLoading } from 'app/components/common/loading/pageLoading'
import { ConfirmationModal } from './components/modals/confirmation'
import { redirectionToSumsub } from 'utils/redirections'

export function ResubmissionItem() {
  const [searchParams] = useSearchParams()

  const refrenceId = searchParams.get('referenceId')
  const userReviewStatus = searchParams.get('userReviewStatus')
  const providerStatus = searchParams.get('providerStatus')

  const isSubmitLoading = useSelector(resubmissionItemSelectors.isSubmitLoading)
  const dispatch = useDispatch()
  const isLoading = useSelector(resubmissionItemSelectors.isLoading)
  const resubmissionItem = useSelector(
    resubmissionItemSelectors.resubmissionItem,
  )

  const today = new Date()
  const fifteenYearsAgo = new Date(
    new Date().setFullYear(new Date().getFullYear() - 15),
  )

  const initialValues: IKYCProviderData = doesAddressExist(
    resubmissionItem.kycProviderData,
  )
    ? {
        firstName: '',
        firstNameEn: '',
        middleName: '',
        middleNameEn: '',
        lastName: '',
        lastNameEn: '',
        dob: '',
        placeOfBirth: '',
        nationality: '',
        number: '',
        issuedDate: '',
        validUntil: '',
        address: {
          street: '',
          streetEn: '',
          subStreet: '',
          subStreetEn: '',
          state: '',
          stateEn: '',
          town: '',
          townEn: '',
          postCode: '',
        },
      }
    : {
        firstName: '',
        middleName: '',
        middleNameEn: '',
        gender: '',
        firstNameEn: '',
        lastName: '',
        lastNameEn: '',
        dob: '',
        placeOfBirth: '',
        nationality: '',
        number: '',
        issuedDate: '',
        validUntil: '',
      }
  const englishCharactersRegex =
    /^[a-zA-Z0-9`.,\-_^&$#@!~"' :;\\|\/?<>()+=%*\[\]{}]+$/

  const validationSchema = Yup.object({
    firstName: resubmissionItem.kycProviderData.firstName?.trim()
      ? Yup.string().required('Required')
      : Yup.string(),
    firstNameEn: resubmissionItem.kycProviderData.firstNameEn?.trim()
      ? Yup.string()
          .required('Required')
          .matches(
            englishCharactersRegex,
            'Value must contain only English characters',
          )
      : Yup.string().matches(
          englishCharactersRegex,
          'Value must contain only English characters',
        ),
    middleName: Yup.string(),
    middleNameEn: Yup.string().matches(
      englishCharactersRegex,
      'Value must contain only English characters',
    ),
    lastName: resubmissionItem.kycProviderData.lastName?.trim()
      ? Yup.string().required('Required')
      : Yup.string(),
    lastNameEn: resubmissionItem.kycProviderData.lastNameEn?.trim()
      ? Yup.string()
          .required('Required')
          .matches(
            englishCharactersRegex,
            'Value must contain only English characters',
          )
      : Yup.string().matches(
          englishCharactersRegex,
          'Value must contain only English characters',
        ),
    dob: resubmissionItem.kycProviderData.dob?.trim()
      ? // dob should be in format of YYYY-MM-DD and it should not be less than 15 years old
        Yup.date()
          .required('Date of birth is required')
          .typeError('DOB must be in YYYY-MM-DD format')
          .max(fifteenYearsAgo, 'User must be at least 15 years old')
      : Yup.string(),
    gender: resubmissionItem.kycProviderData.gender?.trim()
      ? // should be required and only contain M or F
        Yup.string()
          .required('Required')
          .matches(/^[MF]{1}$/, 'Invalid Value, only M or F are allowed')
      : Yup.string(),
    // place of birth should be required only if it is present in the resubmissionItem.kycProviderData
    placeOfBirth: resubmissionItem.kycProviderData.placeOfBirth?.trim()
      ? // place of birth should be 3 letter country code and uppercase
        Yup.string().required('Required')
      : Yup.string(),
    nationality: resubmissionItem.kycProviderData.nationality?.trim()
      ? Yup.string()
          .required('Required')
          .matches(/^[A-Z]{3}$/, 'Invalid country code')
      : Yup.string(),
    number: resubmissionItem.kycProviderData.number?.trim()
      ? Yup.string().required('Required')
      : Yup.string(),
    // issue date should be in YYYY-MM-DD format and should not be in the future
    issuedDate: resubmissionItem.kycProviderData.issuedDate?.trim()
      ? Yup.date()
          .required('Issue date is required')
          .typeError('Issue date must be in YYYY-MM-DD format')
          .max(today, 'Issue date cannot be in the future')
      : Yup.date()
          .typeError('Issue date must be in YYYY-MM-DD format')
          .max(today, 'Issue date cannot be in the future'),
    // validUntil should be in YYYY-MM-DD format and should not be in the past
    validUntil: resubmissionItem.kycProviderData.validUntil?.trim()
      ? Yup.date()
          .required('Valid until date is required')
          .typeError('Valid until must be in YYYY-MM-DD format')
          .min(today, 'Valid until date cannot be in the past')
      : Yup.date()
          .typeError('Valid until must be in YYYY-MM-DD format')
          .min(today, 'Valid until date cannot be in the past'),
    address: doesAddressExist(resubmissionItem.kycProviderData)
      ? Yup.object({
          street: resubmissionItem.kycProviderData.address?.street?.trim()
            ? Yup.string().required('Required')
            : Yup.string(),
          streetEn: resubmissionItem.kycProviderData.address?.streetEn?.trim()
            ? Yup.string().required('Required')
            : Yup.string(),
          subStreet: resubmissionItem.kycProviderData.address?.subStreet?.trim()
            ? Yup.string().required('Required')
            : Yup.string(),
          subStreetEn:
            resubmissionItem.kycProviderData.address?.subStreetEn?.trim()
              ? Yup.string().required('Required')
              : Yup.string(),
          state: resubmissionItem.kycProviderData.address?.state?.trim()
            ? Yup.string().required('Required')
            : Yup.string(),
          stateEn: resubmissionItem.kycProviderData.address?.stateEn?.trim()
            ? Yup.string().required('Required')
            : Yup.string(),
          town: resubmissionItem.kycProviderData.address?.town?.trim()
            ? Yup.string().required('Required')
            : Yup.string(),
          townEn: resubmissionItem.kycProviderData.address?.townEn?.trim()
            ? Yup.string().required('Required')
            : Yup.string(),
          postCode: resubmissionItem.kycProviderData.address?.postCode?.trim()
            ? Yup.string().required('Required')
            : Yup.string(),
        })
      : Yup.object(),
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      const valToSend: IKYCProviderData = {
        idDocType: resubmissionItem.kycProviderData.idDocType,
        country: resubmissionItem.kycProviderData.country,
        ...values,
      }
      dispatch(resubmissionItemActions.setKycData(valToSend))
    },
  })

  useEffect(() => {
    refrenceId &&
      dispatch(resubmissionItemActions.fetchResubmissionItem(refrenceId))
  }, [refrenceId])

  const hadleFormSubmit = () => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length > 0) {
        Object.keys(errors).forEach(fieldName => {
          formik.setFieldTouched(fieldName, true)
        })

        const firstErrorField = FIELD_ORDER.find((field: string) =>
          errors.hasOwnProperty(field),
        )

        const firstErrorFieldElement = document.querySelector<HTMLInputElement>(
          `[name="${firstErrorField}"]`,
        )

        if (firstErrorFieldElement) {
          firstErrorFieldElement.scrollIntoView({
            behavior: 'smooth',
          })
        }

        customToast.error('Please fill all the required fields properly')
      } else {
        formik.handleSubmit()
        dispatch(resubmissionItemActions.setDisplayConfirmationModal(true))
      }
    })
  }

  return (
    <Wrapper>
      {isLoading ? (
        <PageLoading />
      ) : (
        <div>
          <TitleAndExportWrapper>
            <Title>
              Resubmission Item for id doc:{' '}
              {resubmissionItem.kycProviderData.idDocType}
            </Title>
            <ExportButton
              onClick={() => redirectionToSumsub(resubmissionItem.applicantId)}
            >
              View in Sumsub
            </ExportButton>
          </TitleAndExportWrapper>
          <HeaderContainer>
            <HeaderTitle>Field</HeaderTitle>
            <HeaderTitle>Provider Suggestion</HeaderTitle>

            <HeaderTitle>User Suggestion</HeaderTitle>
            <HeaderTitle>Officer Input</HeaderTitle>
          </HeaderContainer>
          {Object.keys(initialValues).map(key => {
            const safeKey = key as keyof IKYCProviderData

            if (
              typeof initialValues[safeKey] === 'object' &&
              initialValues[safeKey] !== null &&
              isIKYCProviderAddress(initialValues[safeKey])
            ) {
              return Object.keys(initialValues[safeKey] as Object).map(
                nestedKey => {
                  const nestedSafeKey = nestedKey as keyof IKYCProviderAddress

                  return (
                    <ResubmissionRow
                      key={`${key}.${nestedSafeKey}`}
                      name={`${key}.${nestedSafeKey}`}
                      onChange={formik.handleChange}
                      onFormikValueChange={value => {
                        formik.setFieldValue(`${key}.${nestedSafeKey}`, value)
                      }}
                      value={
                        formik.values[safeKey]
                          ? (formik.values[safeKey] as IKYCProviderAddress)[
                              nestedSafeKey
                            ]
                          : ''
                      }
                      hasError={
                        formik.touched[safeKey] &&
                        !!getIn(formik.errors, `${safeKey}.${nestedSafeKey}`)
                      }
                      userValue={
                        resubmissionItem.userSuggestion[safeKey]
                          ? (
                              resubmissionItem.userSuggestion[
                                safeKey
                              ] as IKYCProviderAddress
                            )[nestedSafeKey]
                          : ''
                      }
                      providerValue={
                        resubmissionItem.kycProviderData[safeKey]
                          ? (
                              resubmissionItem.kycProviderData[
                                safeKey
                              ] as IKYCProviderAddress
                            )[nestedSafeKey]
                          : ''
                      }
                      errorText={
                        formik.touched[safeKey] &&
                        getIn(formik.errors, `${safeKey}.${nestedSafeKey}`)
                          ? (getIn(
                              formik.errors,
                              `${safeKey}.${nestedSafeKey}`,
                            ) as string)
                          : ''
                      }
                    />
                  )
                },
              )
            }

            return (
              <ResubmissionRow
                key={key}
                name={key}
                onChange={formik.handleChange}
                onFormikValueChange={value => {
                  formik.setFieldValue(key, value)
                }}
                value={formik.values[safeKey]?.toString() || ''}
                hasError={formik.touched[safeKey] && !!formik.errors[safeKey]}
                userValue={
                  resubmissionItem.userSuggestion[safeKey]?.toString() || ''
                }
                providerValue={
                  resubmissionItem.kycProviderData[safeKey]?.toString() || ''
                }
                errorText={
                  formik.touched[safeKey]
                    ? (formik.errors[safeKey] as string)
                    : ''
                }
              />
            )
          })}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '20px',
              marginTop: '20px',
            }}
          >
            <ExportButton
              onClick={() => {
                hadleFormSubmit()
              }}
              type="submit"
              style={{ margin: '16px 0' }}
              isLoading={isSubmitLoading}
              disabled={
                !(
                  userReviewStatus === 'REJECTED' ||
                  providerStatus === 'OFFICER_PROVIDER_PENDING'
                )
              }
            >
              {isSubmitLoading ? 'Submitting...' : 'Submit'}
            </ExportButton>
          </div>
        </div>
      )}
      <ConfirmationModal />
    </Wrapper>
  )
}
