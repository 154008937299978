//FIXME: we must remove this folder and this is a templete

import { EmailInvalidationsHeader } from './components/Header'
import { FilterEmailInvalidationsModal } from './components/modals/filter'
import { EmailInvalidationsTable } from './components/table'
import { Wrapper } from './styles'

interface Props {}

export function EmailInvalidations(props: Props) {
  return (
    <Wrapper>
      <EmailInvalidationsHeader />
      <EmailInvalidationsTable />
      <FilterEmailInvalidationsModal />
    </Wrapper>
  )
}
