//FIXME: we must remove this folder and this is a templete

import { DocumentRejectionsHeader } from './components/Header'
import { FilterDocumentRejectionsModal } from './components/modals/filter'
import { DocumentRejectionsTable } from './components/table'
import { Wrapper } from './styles'

interface Props {}

export function DocumentRejections(props: Props) {
  return (
    <Wrapper>
      <DocumentRejectionsHeader />
      <DocumentRejectionsTable />
      <FilterDocumentRejectionsModal />
    </Wrapper>
  )
}
