import { capitalizeAllCharacters, parseToNumber } from 'utils/string'
import { ISortType } from './types'
import { ColumnOfTable } from 'app/components/table/sortableTable'

export const STATUS_OF_PAYMENTS = ['COMPLETED', 'FAILED']
export const TYPES_OF_PAYMENT_PROVIDER_CODE = ['STRIPE', 'COINBASE_COMMERCE']
export const PAYMENTS_TABLE_KEY = 'paymentsTable'
export const MAX_SAFE_INTEGER = 10000

export const columnsForShowInPaymentsTable: ColumnOfTable[] = [
  { id: 'coreID', label: 'CoreID' },
  { id: 'externalID', label: 'External ID' },
  { id: 'amount', label: 'Amount' },
  { id: 'status', label: 'Status' },
  { id: 'feePercentage', label: 'Fee Percentage' },
  { id: 'orderID', label: 'Order ID' },
  { id: 'paymentProviderCode', label: 'Payment Provider' },
  { id: 'realAmount', label: 'Real Amount' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updatedAt', label: 'Updated At', isDate: true },
  { id: 'needToRefundReason', label: 'Refund Reason' },
  { id: 'usdAmount', label: 'USD Amount' },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${PAYMENTS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${PAYMENTS_TABLE_KEY}-payment`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${PAYMENTS_TABLE_KEY}-paymentBy`) ?? undefined
