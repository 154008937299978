import { Chip, Stack } from '@mui/material'
import { rolesSelectors } from 'app/containers/Roles/selectors'
import { rolesActions } from 'app/containers/Roles/slice'
import { Role, Route } from 'app/containers/Roles/types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ROW_ALIGN_START__JUSTIFY_START } from 'styles/globalStyles'

interface RoutesListProps {
  roleId: number | string
}
export const RoutesList = ({ roleId }: RoutesListProps) => {
  const dispatch = useDispatch()
  const routesList = useSelector(rolesSelectors.routesList)
  const isAddingRoute = useSelector(rolesSelectors.isAddingRoute)

  const role = useSelector(rolesSelectors.roleById(roleId))

  if (!role) return <></>

  const roleRoutes = role?.routeInfo

  const handleDelete = (route: Route) => {
    console.info('You clicked the delete icon.')
    dispatch(
      rolesActions.setDeleteConfirmationModalData({
        display: true,
        role,
        route,
      }),
    )
  }
  const handleClick = (route: Route) => {
    console.info('You clicked the Chip.')
    dispatch(rolesActions.addRouteToRole({ role, route }))
  }

  return (
    <RoutesWrapper>
      {routesList?.map(routeListItem => {
        const isRouteSelected = roleRoutes?.some(
          roleRoute => roleRoute.id === routeListItem.id,
        )
        return isRouteSelected ? (
          <Chip
            key={routeListItem.id}
            label={routeListItem.path}
            onClick={() => handleDelete(routeListItem)}
            onDelete={() => handleDelete(routeListItem)}
            variant="outlined"
          />
        ) : (
          <Chip
            key={routeListItem.id}
            label={routeListItem.path}
            onClick={() => handleClick(routeListItem)}
            disabled={isAddingRoute === routeListItem.path}
          />
        )
      })}
    </RoutesWrapper>
  )
}

const RoutesWrapper = styled.div`
  ${ROW_ALIGN_START__JUSTIFY_START}
  flex-wrap: wrap;
  gap: 10px;
  padding: 16px;
`
