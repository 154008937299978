import { ColumnOfTable } from 'app/components/table/sortableTable'
import {
  capitalize,
  capitalizeAllCharacters,
  parseToNumber,
} from 'utils/string'
import { ISortType } from './types'

export const APIKEYS_TABLE_KEY = 'apiKeysTable'

export const columnsForShowInApiKeysTable: ColumnOfTable[] = [
  { id: 'uuID', label: 'UUID' },
  { id: 'clientDomainID', label: 'Client Domain ID' },
  { id: 'clientDomainUuid', label: 'Client Domain UUID' },
  { id: 'token', label: 'Token' },
  { id: 'createdAt', label: 'Created At', isDate: true },
  { id: 'updateAt', label: 'Updated At', isDate: true },
]

export const localPageSize = parseToNumber(
  localStorage.getItem(`${APIKEYS_TABLE_KEY}_pageSize`),
)

export const localSortType = capitalizeAllCharacters(
  localStorage.getItem(`${APIKEYS_TABLE_KEY}-order`) ?? '',
) as ISortType

export const localSortField =
  localStorage.getItem(`${APIKEYS_TABLE_KEY}-orderBy`) ?? undefined

export const MAX_SAFE_INTEGER = 10000
