import { useDispatch, useSelector } from 'react-redux'
import { Box, Modal, styled } from '@mui/material'
import { ConfirmationContent } from './content'
import { resubmissionItemActions } from 'app/containers/ResubmissionItem/slice'
import { resubmissionItemDomains } from 'app/containers/ResubmissionItem/selectors'
import { FC } from 'react'
import { mediaQueries } from 'styles/mediaQueries'

interface IConfirmationModalProps {}

export const ConfirmationModal: FC<IConfirmationModalProps> = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(resubmissionItemDomains.displayConfirmationModal)
  const handleModalClose = () => {
    dispatch(resubmissionItemActions.setDisplayConfirmationModal(false))
  }

  return (
    <Modal open={isOpen} onClose={handleModalClose}>
      <Container>
        <ConfirmationContent />
      </Container>
    </Modal>
  )
}

const Container = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 800px;
  background-color: var(--white);
  border: none;
  box-shadow: 24px;
  padding: 32px;
  overflow: auto;
  ${mediaQueries.between('sm', 'md')`
  width:750px
  `}
  ${mediaQueries.lessThan('sm')`  
  width:550px;
  padding: 20px;
  `}
  ${mediaQueries.lessThan('xs')`
  width:300px;
  padding: 10px;
  `}
`
